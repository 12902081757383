import "./index.css";

export default function DotsLoader() {
    return (
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
