/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SearchResultBlock from "../../components/Block";
import { useSnackbar } from "notistack";
import { Peril, ResultBlockType } from "../../types";
import { useTranslation } from "react-i18next";
import DataGridComponent from "../../components/DataGridComponent/DataGridComponent";
import { GridColDef } from "@mui/x-data-grid";
import Icon from "../../components/Icon";
import { selectAddress, selectAutoComplete } from "../address/addressSlice";
import {
  createNewPerilAction,
  deletePerilAction,
  fetchPerilAction,
  selectArretePeril,
  updatePerilAction,
} from "../arretPeril/arretPerilSlice";
import PlacesAutocomplete from "./PlacesAutocomplete";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputDate from "./CustomInputDate";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import { selectUserInfo } from "../userInfo/userInfoSlice";
import "./Search.css";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #CCCCCC",
  boxShadow: 24,
  p: 4,
  paddingBottom: "10px",
  borderRadius: "5px",
};

const ButtonBlue = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#1C5782",
  borderColor: "#1C5782",
  color: "#fff",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
});

const ButtonCancel = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  color: "#282828",
  fontWeight: 600,
  border: "1px solid #ABABAB",
});

export default function PerilRiskListBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  const { enqueueSnackbar } = useSnackbar();
  const [perilsList, setPerilsList] = useState<Peril[]>([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const arretePeril = useAppSelector(selectArretePeril);
  const address = useAppSelector(selectAddress);
  const userInfo = useAppSelector(selectUserInfo);
  const [editableRowIndex, setEditableRowIndex] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    new Date()
  );
  const [textInput, setTextInput] = useState("");
  const googleAutocomplete = useAppSelector(selectAutoComplete);
  const [place, setPlace] = useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPlace(googleAutocomplete?.label);
    setTextInput("");
    setSelectedEndDate(new Date());
    setSelectedStartDate(new Date());
    setEditableRowIndex(null);
    setOpen(false);
  };

  useEffect(() => {
    setPlace(googleAutocomplete?.label);
  }, [googleAutocomplete]);

  useEffect(() => {
    if (address.error) {
      enqueueSnackbar("L'interrogation a échoué", {
        variant: "error",
      });
    }
    if (address.value) {
      dispatch(
        fetchPerilAction(address?.value?.latitude, address?.value?.longitude)
      );
    }
  }, [address]);

  useEffect(() => {
    if (arretePeril.value) {
      let newPerilList: Peril[] = [];
      if (arretePeril.value.listPerils) {
        newPerilList = arretePeril.value.listPerils.map((item: any) => ({
          id: item._id,
          address: item.properties.address,
          startDate: moment.utc(item.properties.startDate).format("DD/MM/YYYY"),
          endDate: moment.utc(item.properties.endDate).format("DD/MM/YYYY"),
          url: item.properties.url,
        }));
      }
      setPerilsList(newPerilList);
    }

    // after editing, if the edit succeeded on the server, refresh list from server
    if (arretePeril?.value?.success) {
      dispatch(
        fetchPerilAction(address?.value?.latitude, address?.value?.longitude)
      );
    }
  }, [arretePeril]);

  const isAdmin = userInfo.value && userInfo.value.groups && userInfo.value.groups.includes("/admin");

  const handleDeleteArretPeril = (id: any) => {
    dispatch(deletePerilAction(id));
  };

  const columnsAdmin: GridColDef[] = [
    {
      field: "address",
      headerName: t("adresse"),
      flex: 6,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: t("startDate"),
      flex: 3,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "endDate",
      headerName: t("endDate"),
      flex: 3,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "url",
      headerName: t("url"),
      flex: 4,
      minWidth: 80,
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <a href={params?.row.url} target="_blank" rel="noreferrer">{params?.row.url}</a>
        </div>
      )
    },
    {
      field: "modify",
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Icon
          type="pencilBlack"
          iconStyle={{ height: "20px", width: "20px" }}
        />
      ),
      renderCell: (params: any) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditableRowIndex(params.id as string);
            setPlace(params?.row.address);
            setSelectedStartDate(
              moment.utc(params?.row.startDate, "DD/MM/YYYY").toDate()
            );
            setSelectedEndDate(
              moment.utc(params?.row.endDate, "DD/MM/YYYY").toDate()
            );
            setTextInput(params?.row.url);
            handleOpen();
          }}
        >
          <Icon
            type="pencilGray"
            iconStyle={{ height: "20px", width: "20px" }}
          />
        </div>
      ),
      minWidth: 40,
      maxWidth: 40,
    },
    {
      field: "trash",
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Icon type="trashBlack" iconStyle={{ height: "20px", width: "20px" }} />
      ),
      renderCell: (params: any) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteArretPeril(params.id)}
        >
          <Icon
            type="trashGray"
            iconStyle={{ height: "20px", width: "20px" }}
          />
        </div>
      ),
      minWidth: 40,
      maxWidth: 40,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: t("adresse"),
      flex: 6,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: t("startDate"),
      flex: 4,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "endDate",
      headerName: t("endDate"),
      flex: 4,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "url",
      headerName: t("url"),
      flex: 4,
      minWidth: 80,
      sortable: false,
    },
  ];

  const handleValider = () => {
    if (place) {
      const newPeril = {
        address: place,
        startDate: moment.utc(selectedStartDate).toDate(),
        endDate: moment.utc(selectedEndDate).toDate(),
        url: textInput,
      };
      if (editableRowIndex) {
        dispatch(updatePerilAction(editableRowIndex, { newPeril }));
      } else {
        dispatch(createNewPerilAction({ newPeril }));
      }
    }
    handleClose();
  };

  return (
    <SearchResultBlock
      style={{
        padding: "20px 20px 20px 20px",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <DataGridComponent
        rows={perilsList}
        cols={isAdmin ? columnsAdmin : columns}
        textNorows={t("noPeril")}
      />
      {isAdmin && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleOpen} disabled={!address.value}>
            <AddCircleIcon
              sx={{ color: address.value ? "#5387FF" : "rgba(0, 0, 0, 0.12)" }}
              fontSize="large"
            />
          </IconButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-add-peril"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                {t("addPeril")}
              </Typography>

              <div className="blocModal">
                <div className="blocModalAddress">
                  <PlacesAutocomplete
                    initialValue={place}
                    onChange={(value: any) => setPlace(value?.label)}
                  />
                </div>
                <div className="blocModalDatePickerRange">
                  <DatePicker
                    customInput={<CustomInputDate />}
                    selected={selectedStartDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date) => setSelectedStartDate(date)}
                  />
                  <HorizontalRuleIcon />
                  <DatePicker
                    customInput={<CustomInputDate />}
                    selected={selectedEndDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date) => setSelectedEndDate(date)}
                  />
                </div>
                <input
                  style={{
                    height: "38px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 4,
                    paddingLeft: 8,
                  }}
                  placeholder="Entrer un URL"
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonCancel
                    variant="text"
                    style={{ width: "30%", margin: 10 }}
                    onClick={handleClose}
                  >
                    Annuler
                  </ButtonCancel>
                  <ButtonBlue
                    style={{ width: "30%", margin: 10 }}
                    onClick={handleValider}
                  >
                    Valider
                  </ButtonBlue>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </SearchResultBlock>
  );
}
