import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import {
    getBuildingAgeDetectionModelData,
    getGraffitiDetectionModelData,
    getPoolDetectionModelData,
    getRoofDetectionModelData,
    getSolarPanelDetectionModelData,
    getWalledWindowDetectionModelData
} from './aiDetectionAPI'
import {
    fetchBuildingAgeModelDataAction,
    fetchGraffitiModelDataAction,
    fetchPoolModelDataAction,
    fetchRoofModelDataAction,
    fetchSolarPanelModelDataAction,
    fetchWalledWindowModelDataAction,
    resetAction
} from './aiDetectionSlice'
import { fetchAddressAction, selectAddress } from './addressSlice';

function generateSaga(actionCreator: any, apiCall: any) {
    return function* (action: any): any {
        try {
            yield put(actionCreator.pending())
            const address = yield select(selectAddress)
            const { placeId } = address.value
            const modelData: any = yield call(apiCall, { placeId })
            yield put(actionCreator.fulfilled(modelData))
            return modelData
        } catch (e: any) {
            yield put(actionCreator.rejected(e.message));
        }
    }
}

const fetchBuildingAgeDetectionModelData = generateSaga(fetchBuildingAgeModelDataAction, getBuildingAgeDetectionModelData)
const fetchGraffitiDetectionModelData = generateSaga(fetchGraffitiModelDataAction, getGraffitiDetectionModelData)
const fetchRoofDetectionModelData = generateSaga(fetchRoofModelDataAction, getRoofDetectionModelData)
const fetchPoolDetectionModelData = generateSaga(fetchPoolModelDataAction, getPoolDetectionModelData)
const fetchSolarPanelDetectionModelData = generateSaga(fetchSolarPanelModelDataAction, getSolarPanelDetectionModelData)
const fetchWalledWindowlDetectionModelData = generateSaga(fetchWalledWindowModelDataAction, getWalledWindowDetectionModelData)

const resetAIState = function* (action: any) {
    yield put(resetAction())
}
function* aiDetectionSaga() {
    yield all([
        takeLatest(fetchBuildingAgeModelDataAction, fetchBuildingAgeDetectionModelData),
        takeLatest(fetchGraffitiModelDataAction, fetchGraffitiDetectionModelData),
        takeLatest(fetchPoolModelDataAction, fetchPoolDetectionModelData),
        takeLatest(fetchRoofModelDataAction, fetchRoofDetectionModelData),
        takeLatest(fetchSolarPanelModelDataAction, fetchSolarPanelDetectionModelData),
        takeLatest(fetchWalledWindowModelDataAction, fetchWalledWindowlDetectionModelData),
        takeLatest(fetchAddressAction.fulfilled, resetAIState),
    ])

}

export default aiDetectionSaga;