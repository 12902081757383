import { call, put, takeLatest } from "redux-saga/effects";
import { fetchUserInfoAction } from "./userInfoSlice";
import { getUserInfo } from "../address/data4homeAPI";

function* fetchUserInfo(action: any): any {
  try {
    yield put(fetchUserInfoAction.pending());
    const userInfo: any = yield call(getUserInfo);
    yield put(fetchUserInfoAction.fulfilled(userInfo));
  } catch (exception: any) {
    yield put(fetchUserInfoAction.rejected(exception.message));
  }
}

function* adressSaga() {
  yield takeLatest(fetchUserInfoAction, fetchUserInfo);
}

export default adressSaga;
