import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import SearchResultBlock from "../../components/Block";
import {selectAddress} from "../address/addressSlice";
import BusinessData from "../address/BusinessData";
import {
    fetchBusinessWithin50mAction,
    selectBusinessWithin50m,
    fetchBusinessNafCodesAction,
    selectBusinessNafCodes,
} from "../address/businessSlice";
import ActivitiesFilterSearch from "../../components/ActivitiesFilterSearch";
import {useSnackbar} from "notistack";
import {ResultBlockType} from "../../types";

export default function BusinessSearchResultBlock({id, iconType, title}: ResultBlockType) {
    const dispatch = useAppDispatch();
    const address = useAppSelector(selectAddress);
    const businessesWithin50m = useAppSelector(selectBusinessWithin50m);
    const businessesNafCodes = useAppSelector(selectBusinessNafCodes);
    const {enqueueSnackbar} = useSnackbar();
    const [filter, setFilter] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        if (businessesNafCodes.value || businessesNafCodes.status !== "idle") {
            return
        }
        dispatch(fetchBusinessNafCodesAction());
    }, [dispatch, businessesNafCodes.status, businessesNafCodes.value]);

    useEffect(() => {
        if (!address.value || address.status !== "idle") {
            return;
        }
        dispatch(
            fetchBusinessWithin50mAction({
                lat: address.value.latitude,
                lng: address.value.longitude,
                nafCodeFilter: undefined,
                resultPerPage: 15,
                page: 1,
            })
        );
    }, [address, dispatch]);

    useEffect(
        function () {
            if (businessesNafCodes.error) {
                enqueueSnackbar("L'interrogation de la liste des activités a échoué", {variant: "error"});
            }
        },
        [businessesNafCodes, enqueueSnackbar]
    );

    useEffect(
        function () {
            if (businessesWithin50m.error) {
                enqueueSnackbar("L'interrogation de la liste des entreprises dans un rayon de 50m a échoué", {variant: "error"});
            }
        },
        [businessesWithin50m, enqueueSnackbar]
    );

    const dispatchFetchBusinessWithin50m = (page: number, filter: string[] | undefined = undefined) => {
        dispatch(
            fetchBusinessWithin50mAction({
                lat: address.value.latitude,
                lng: address.value.longitude,
                nafCodeFilter: filter && filter.length > 0 ? filter : undefined,
                resultPerPage: 15,
                page: page,
            })
        );
    };

    const handleFilterChange = (values: string[]) => {
        setFilter(values);
        dispatchFetchBusinessWithin50m(1, values);
    };

    const handleBusinessesWithin50mPageChange = (page: number) => {
        dispatchFetchBusinessWithin50m(page, filter);
    };

    return (
        <SearchResultBlock style={{paddingBottom: "20px"}} title={title} iconType={iconType} id={id}>
            <ActivitiesFilterSearch disabled={!address.value} activitiesOptions={businessesNafCodes.value} onChange={handleFilterChange} />
            <BusinessData
                businesses={businessesWithin50m}
                loading={businessesWithin50m.status === "loading"}
                itemsPerPage={10}
                onPageChange={handleBusinessesWithin50mPageChange}
            />
        </SearchResultBlock>
    );
}
