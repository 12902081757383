import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';

import CustomInput from './CustomInput';
import Icon from '../../components/Icon';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  calendarIcon: {
    marginLeft: 5,
    marginRight: 5,
  },
  datePickerInput: {
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
});

interface DatePickerRangeProps {
  dateFormat?: string;
  disableStartDate?: boolean;
  disableEndDate?: boolean;
  onChange: (values: { startDate: Date; endDate: Date }) => void;
  onNextStartDate?: () => void;
  onNextEndDate?: () => void;
  onPrevStartDate?: () => void;
  onPrevEndDate?: () => void;
  startDate: Date;
  endDate: Date;
}

const DatePickerRange: React.FC<DatePickerRangeProps> = ({
  disableStartDate,
  disableEndDate,
  onChange,
  onNextStartDate,
  onNextEndDate,
  onPrevStartDate,
  onPrevEndDate,
  startDate,
  endDate,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <DatePicker
        customInput={<CustomInput onNext={onNextStartDate} onPrev={onPrevStartDate}/>}
        dateFormat='dd/MM/yyyy'
        disabled={disableStartDate}
        selected={startDate}
        onChange={(date: Date) => onChange({ startDate: date, endDate })}
        withPortal
      />
      <Icon type="calendarDateTime"/>
      <DatePicker
        customInput={<CustomInput onNext={onNextEndDate} onPrev={onPrevEndDate}/>}
        dateFormat='dd/MM/yyyy'
        disabled={disableEndDate}
        selected={endDate}
        minDate={startDate}
        onChange={(date : Date) => onChange({ startDate, endDate: date })}
        withPortal
      />
    </div>
  );
};

DatePickerRange.propTypes = {
  dateFormat: PropTypes.string,
  disableStartDate: PropTypes.bool,
  disableEndDate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onNextStartDate: PropTypes.func,
  onNextEndDate: PropTypes.func,
  onPrevStartDate: PropTypes.func,
  onPrevEndDate: PropTypes.func,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

DatePickerRange.defaultProps = {
  disableStartDate: false,
  disableEndDate: false,
};

export default DatePickerRange;
