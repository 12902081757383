import { Route, Routes } from "react-router-dom";
import SignIn from "./Signin";
import "./Auth.css";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

export default function Auth() {
  return (
    <div className="AuthContainer">
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}
