import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Icon from "../../components/Icon";
import { FormEvent, useState } from "react";
import AuthService from "./authService";
import AuthInput from "./AuthInput";
import { useTranslation } from "react-i18next";
import AuthButton from "./AuthButton";
import "./Auth.css";
import Copyright from "./Copyright";
import AuthAlert from "./AuthAlert";
const theme = createTheme();

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState<"success" | "error">("success");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await AuthService.forgotPassword(
        data.get("email")!.toString()
      );
      if (response.status === 200) {
        setOpen(true);
        setMessage(t("sendMailSuccess"));
        setTypeAlert("success");
        setTimeout(() => setOpen(false), 5000);
      }
    } catch (error: any) {
      console.log(error);
      setOpen(true);
      setMessage(error.response.data.error);
      setTypeAlert("error");
      setTimeout(() => setOpen(false), 5000);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            width: "550px",
            height: "650px",
            justifyContent: "space-between",
          }}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Box margin="20px">
                <Icon type="logo_d4r" />
              </Box>
              <AuthAlert open={open} message={message} type={typeAlert} />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              display="flex"
              flexDirection="column"
              height={350}
            >
              <p className="AuthTextTitle">{t("forgotPasswordTitle")}</p>
              <AuthInput
                id="email"
                type="text"
                icon="mail"
                name="email"
                label={t("email")}
              />
              <AuthButton type="submit" label={t("send")} />
              <Box display="flex" justifyContent="center">
                <Link
                  to="/auth/signin"
                  style={{ color: "#3C5278", fontSize: "16px" }}
                >
                  {t("cancel")}
                </Link>
              </Box>
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
