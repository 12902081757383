import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSevesoAction } from "./sevesoSlice";
import { getListSiteSevesoByPlaceId } from "../address/data4homeAPI";

function* fetchSeveso(action: any): any {
  try {
    const { placeId, distance } = action.payload;
    yield put(fetchSevesoAction.pending());
    const sites_seveso: any = yield call(getListSiteSevesoByPlaceId, {
      placeId,
      distance,
    });
    yield put(fetchSevesoAction.fulfilled(sites_seveso));
  } catch (exception: any) {
    yield put(fetchSevesoAction.rejected(exception.message));
  }
}

function* sevesoSaga() {
  yield takeLatest(fetchSevesoAction, fetchSeveso);
}

export default sevesoSaga;
