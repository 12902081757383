import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SearchResultBlock from "../../components/Block";
import { useSnackbar } from "notistack";
import { ResultBlockType, Seveso } from "../../types";
import { useTranslation } from "react-i18next";
import DataGridComponent from "../../components/DataGridComponent/DataGridComponent";
import { GridColDef } from "@mui/x-data-grid";
import { fetchSevesoAction, selectSeveso } from "../seveso/sevesoSlice";
import { selectAddress } from "../address/addressSlice";

export default function SiteSevesoListBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const sites_seveso = useAppSelector(selectSeveso);
  const { enqueueSnackbar } = useSnackbar();
  const [sevesoList, setSevesoList] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!address?.value) {
      return;
    }
    dispatch(fetchSevesoAction(address.value.placeId, 20000));
  }, [address?.value, dispatch]);

  useEffect(
    function () {
      if (sites_seveso.error) {
        enqueueSnackbar("L'interrogation des sites seveso a échoué", {
          variant: "error",
        });
      }
      let newSevesoList: Seveso[] = [];
      if (sites_seveso.value) {
        newSevesoList = sites_seveso.value.map((item: any, index: any) => ({
          id: index, // id is necessary for the table DataGrid MUI
          nom: item.properties.nom,
          adresse: item.properties.adresse,
          lib_seveso: item.properties.lib_seveso,
          etat_activite: item.properties.etat_activite,
          distance: item.properties.distance?.calculated,
        }));
      }
      setSevesoList(newSevesoList);
    },
    [sites_seveso, enqueueSnackbar]
  );

  const columns: GridColDef[] = [
    {
      field: "nom",
      headerName: "Nom",
      flex: 5,
      sortable: false,
      minWidth: 80,
      cellClassName: "dataCellText",
    },
    {
      field: "adresse",
      headerName: "Adresse",
      flex: 7,
      sortable: false,
      cellClassName: "dataCellText",
    },
    {
      field: "distance",
      headerName: "Distance",
      cellClassName: "dataCellText",
      flex: 2,
      renderCell: (params) => (
        <span>{(params.value / 1000).toFixed(2)} km</span>
      ),
      sortable: false,
    },
    {
      field: "lib_seveso",
      headerName: "Type",
      flex: 2,
      sortable: false,
      cellClassName: "dataCellText",
    },
    {
      field: "etat_activite",
      headerName: "Statut",
      flex: 2,
      sortable: false,
      cellClassName: "dataCellText",
    },
  ];

  return (
    <SearchResultBlock
      style={{
        padding: "20px",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <DataGridComponent
        rows={sevesoList}
        cols={columns}
        textNorows={t("noSeveso")}
      />
    </SearchResultBlock>
  );
}
