import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface SevesoState {
  status: "idle" | "loading" | "failed";
  value?: any;
  error?: string;
}

const initialState: SevesoState = {
  status: "idle",
  value: undefined,
  error: undefined,
};

export const fetchSevesoAction = createAction(
  "seveso/fetch",
  function prepare(placeId: string, distance: number) {
    return {
      payload: {
        placeId,
        distance,
      },
    };
  }
) as any;
fetchSevesoAction.pending = createAction("seveso/fetch/pending");
fetchSevesoAction.fulfilled = createAction(
  "seveso/fetch/fulfilled",
  function prepare(sites_seveso: any) {
    return {
      payload: {
        sites_seveso,
      },
    };
  }
);
fetchSevesoAction.rejected = createAction(
  "seveso/fetch/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const selectSeveso = (state: RootState) => state.sites_seveso;

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchSevesoAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(fetchSevesoAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload.sites_seveso;
  });
  builder.addCase(fetchSevesoAction.rejected, (state, action) => {
    state.status = "failed";
    state.value = undefined;
    state.error = action.payload.error;
  });
});
