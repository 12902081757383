// slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RequestState {
  response: any | null;
  loading: boolean;
  error: string | null;
}

interface EventState {
  fetch: RequestState,
  create: RequestState,
  update: RequestState,
  delete: RequestState,
}

const eventInitialState: EventState = {
  fetch: {
    response: null,
    loading: false,
    error: null
  },
  create: {
    response: null,
    loading: false,
    error: null
  },
  update: {
    response: null,
    loading: false,
    error: null
  },
  delete: {
    response: null,
    loading: false,
    error: null
  },
};

const eventSlice = createSlice({
  name: 'event',
  initialState: eventInitialState,
  reducers: {
    fetchEventRequest(state, action: PayloadAction<File>) {
      state.fetch.loading = true;
    },
    fetchEventSuccess(state, action: PayloadAction<any>) {
      state.fetch.response = action.payload;
      state.fetch.error = null;
      state.fetch.loading = false;
    },
    fetchEventFailure(state, action: PayloadAction<string>) {
      state.fetch.error = action.payload;
      state.fetch.loading = false;
    },

    createEventRequest(state, action: PayloadAction<File>) {
      state.create.loading = true;
    },
    createEventSuccess(state, action: PayloadAction<any>) {
      state.create.response = action.payload;
      state.create.error = null;
      state.create.loading = false;
    },
    createEventFailure(state, action: PayloadAction<string>) {
      state.create.error = action.payload;
      state.create.loading = false;
    },

    updateEventRequest(state, action: PayloadAction<File>) {
      state.fetch.loading = true;
    },
    updateEventSuccess(state, action: PayloadAction<any>) {
      state.update.response = action.payload;
      state.update.error = null;
      state.update.loading = false;
    },
    updateEventFailure(state, action: PayloadAction<string>) {
      state.update.error = action.payload;
      state.update.loading = false;
    },

    deleteEventRequest(state, action: PayloadAction<File>) {
      state.delete.loading = true;
    },
    deleteEventSuccess(state, action: PayloadAction<any>) {
      state.delete.response = action.payload;
      state.delete.error = null;
      state.delete.loading = false;
    },
    deleteEventFailure(state, action: PayloadAction<string>) {
      state.delete.error = action.payload;
      state.delete.loading = false;
    },
  },
});

// Exporter les actions et le réducteur
export const {
  fetchEventRequest,
  fetchEventSuccess,
  fetchEventFailure
} = eventSlice.actions;
export default eventSlice.reducer;