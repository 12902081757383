import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
export interface IAuthAlertProps {
  message: string;
  type: "error" | "success" | "warning";
  open: boolean;
  style?: any;
}

export default function AuthAlert({
  message,
  type,
  open,
  style,
}: IAuthAlertProps) {
  return (
    <>
      {open && (
        <div className={`AuthAlertContainer ${type}`} style={style}>
          <div className="AuthAlertIcon">
            {type === "error" && <ErrorIcon color="error" />}
            {type === "warning" && <WarningIcon color="warning" />}
            {type === "success" && <CheckCircleIcon color="success" />}
          </div>
          <p className={`AuthAlertText${type}`}>{message}</p>
        </div>
      )}
    </>
  );
}
