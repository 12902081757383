import "./index.css";
import _ from 'lodash';

import Icon from "../Icon";
import StepProgressBar from "../StepProgressBar";

import { scaleLevelType } from "../../constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  type: string;
  catnatTypes: string[];
  title: string;
  content: any | undefined;
  catnats: any[] | undefined;
  events: any[] | undefined;
  isValue: boolean;
  rcp: string;
  scaleLevel?: scaleLevelType;
}

const containerIconStyle: Record<string, string> = {
  justifyContent: "center",
  alignItems: "center",
  width: "3rem",
  height: "3rem",
  borderRadius: "50%",
  cursor: "pointer",
};

export default function ClimateItem({
  type,
  catnatTypes,
  title,
  content,
  catnats,
  events,
  isValue,
  rcp,
  scaleLevel,
}: PropsType): JSX.Element {
  const [catnat, setCatnat] = useState<any>(false);
  const [event, setEvent] = useState<any>(null); // setisEvent will be put later when correctly used
  const { t } = useTranslation();

  useEffect(() => {
    if (catnats) {
      const matchingCatnats = catnats.filter((catnat) => catnat.type.some((type: string) => catnatTypes.includes(type)))
      if (matchingCatnats.length) {
        setCatnat(matchingCatnats[0]);
      } else {
        // reset if no matching event
        setCatnat(null);
      }
    }

    if (events) {
      const matchingEvents = events.filter(function(event) {
        const snakeCaseType = _.snakeCase(type)
        return event.risk_type === snakeCaseType
      })
      if (matchingEvents.length) {
        setEvent(matchingEvents[0]);
      } else {
        // reset if no matching event
        setEvent(null);
      }
    }
  }, [catnats, content, events, catnatTypes, type]);

  return (
    <div className="ClimateItemWrapper">
      <div className="RiskAlert">
        <div className="RiskBarIcon">
          <Icon
            type={`${type}_svg`}
            size="1.75rem"
            containerStyle={{
              ...containerIconStyle,
              backgroundColor: scaleLevel?.color,
            }}
          />
          <div>
            <span className="RiskTitle">{title}</span>
            <StepProgressBar step={scaleLevel!.num} color={scaleLevel!.color!} />

            {content || content === null ? (
              <div className="RiskLabel">{t(scaleLevel!.label!)}</div>
            ) : (
              // blank line when we dont have this climate risk
              <div className="RiskLabel">
                <br />
              </div>
            )}
          </div>
          {(!!catnat) && (
            <div className="Warning">
              <a href={`/#catnat_risk`}>
                <Icon type="warning" />
              </a>
            </div>
          )}
          {(!!event) && (
            <div className="Event">
              <a href={`/#event_risk`}>
                <Icon type="document" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
