import { call, put, takeLatest } from 'redux-saga/effects'
import { createOrUpdateAlertAction } from './alertEditorSlice'
import { createOrUpdateAlertForMap } from '../address/data4homeAPI'

function* createOrUpdateAlert(action: any): any {
    try {
        const { operation, alert } = action.payload
        yield put(createOrUpdateAlertAction.pending())
        const newAlert: any = yield call(createOrUpdateAlertForMap, {operation, alert})
        yield put(createOrUpdateAlertAction.fulfilled(newAlert))
    } catch (exception) {
        yield put(createOrUpdateAlertAction.rejected(exception))
    }
}

function* adressSaga() {
    yield takeLatest(createOrUpdateAlertAction, createOrUpdateAlert);
}

export default adressSaga;