export const HeavyRainSvg = ({style, size}: any): JSX.Element => (
    <svg viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...style, height: size, width: size}}>
        <rect x="12.2882" y="9.99097" width="1.86877" height="10.8259" rx="0.934387" transform="rotate(39.4353 12.2882 9.99097)" fill="white"/>
        <rect x="19.6127" y="10.6415" width="1.86877" height="10.8259" rx="0.934387" transform="rotate(39.4353 19.6127 10.6415)" fill="white"/>
        <rect x="28.8126" y="10.2884" width="1.86877" height="10.8259" rx="0.934387" transform="rotate(39.4353 28.8126 10.2884)" fill="white"/>
        <rect x="4.12891" y="20.1899" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 4.12891 20.1899)" fill="white"/>
        <rect x="8.84338" y="18.3522" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 8.84338 18.3522)" fill="white"/>
        <rect x="12.2882" y="20.0134" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 12.2882 20.0134)" fill="white"/>
        <rect x="17.8566" y="20.0133" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 17.8566 20.0133)" fill="white"/>
        <rect x="15.6332" y="22.7406" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 15.6332 22.7406)" fill="white"/>
        <rect x="18.9781" y="23.3199" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 18.9781 23.3199)" fill="white"/>
        <rect x="21.6141" y="19.925" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 21.6141 19.925)" fill="white"/>
        <rect x="8.28906" y="22.1329" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 8.28906 22.1329)" fill="white"/>
        <rect x="1.56409" y="23.2317" width="1.86877" height="1.76559" rx="0.882795" transform="rotate(39.4353 1.56409 23.2317)" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.472 11.0239C15.5018 11.058 15.5318 11.0918 15.5623 11.1252H15.4174C15.4361 11.0918 15.4543 11.058 15.472 11.0239ZM11.6064 16.042C11.721 15.9462 11.8278 15.8413 11.9249 15.7277L14.5219 12.6891C15.408 11.6525 15.1586 10.2774 13.965 9.61791C12.7715 8.95837 11.0856 9.26406 10.1996 10.3007L7.60259 13.3393C6.71659 14.3759 6.96592 15.7509 8.1595 16.4105C8.16831 16.4154 8.17716 16.4202 8.18603 16.4249C6.02492 16.3723 4.28979 14.666 4.28979 12.5687C4.28979 10.4383 6.08027 8.71121 8.28894 8.71121C8.69789 8.71121 9.09251 8.77042 9.46407 8.88044C9.61542 7.04635 10.9968 5.61017 12.6786 5.61017C13.1647 5.61017 13.6257 5.73015 14.0392 5.94505C14.4458 3.12281 16.6368 0.967102 19.2786 0.967102C21.8686 0.967102 24.0253 3.03918 24.492 5.77996C24.8016 5.66969 25.1315 5.61017 25.4739 5.61017C27.1284 5.61017 28.4922 7.00016 28.6801 8.79155C28.9454 8.73889 29.2203 8.71121 29.5019 8.71121C31.7265 8.71121 33.5299 10.4383 33.5299 12.5687C33.5299 14.6991 31.7265 16.4262 29.5019 16.4262C28.8692 16.4262 28.2706 16.2865 27.7378 16.0375C27.7786 15.9972 27.8177 15.9552 27.855 15.9116L30.9879 12.246C31.6816 11.4344 31.4864 10.3578 30.5519 9.84143C29.6174 9.32505 28.2975 9.56439 27.6038 10.376L24.4709 14.0416C23.9653 14.6332 23.9319 15.3656 24.3129 15.9191L23.1865 16.042H19.2584L21.7692 13.1043C22.5773 12.1587 22.3499 10.9045 21.2612 10.3029C20.1725 9.70129 18.6348 9.98013 17.8266 10.9257L13.9296 15.4853C13.7784 15.6621 13.6635 15.8498 13.5837 16.042H13.4398H11.6064Z" fill="white"/>
    </svg>
);