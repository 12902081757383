import _ from "lodash"
import { useEffect, useState } from "react"
import AlertAccordionItem from "./AlertAccordionItem"


export interface AlertAccordionListProps {
    alertItemList: any
}

export default function AlertAccordionList ({ alertItemList }: AlertAccordionListProps) {
    const [currentAlert, setCurrentAlert] = useState<string|false|undefined>(_.get(_.first(alertItemList), 'id'))
    const handleChange =
        (alertId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setCurrentAlert(isExpanded ? alertId : false);
        };
    useEffect(() => {
        setCurrentAlert(_.get(_.first(alertItemList), 'id'))
    }, [alertItemList])

    return (
        <div className="AlertAccordionList">
            {alertItemList.length > 0 && (
                alertItemList.map((item: any) => {
                    const itemId = _.get(item, 'id')
                    return (
                        <AlertAccordionItem
                            key={itemId}
                            item={item}
                            expanded={itemId===currentAlert} 
                            onExpand={handleChange(itemId)}/>
                    )
                })
            )}
        </div>
    );
}
