import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { RequestStatus } from '../../types/type'

export type AddressState = {
    autocomplete?: any,
    place: RequestStatus,
    satellite: RequestStatus,
    streetViews: RequestStatus,
    historicMonuments: RequestStatus,
    fireStations: RequestStatus
}

const initialState: AddressState = {
    autocomplete: undefined,
    place: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    satellite: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    streetViews: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    historicMonuments: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    fireStations: {
        status: 'idle',
        value: undefined,
        error: undefined
    }
}

export const setAutoCompleteValue = createAction('address/autocomplete', function prepare(place_id: string) {
    return {
        payload: {
            place_id
        },
    }
}) as any

export const fetchAddressAction = createAction('address/fetch', function prepare(place_id: string) {
    return {
        payload: {
            place_id
        },
    }
}) as any
fetchAddressAction.pending = createAction('address/fetch/pending')
fetchAddressAction.fulfilled = createAction('address/fetch/fulfilled', function prepare(address: any) {
    return {
        payload: {
            address
        }
    }
})
fetchAddressAction.rejected = createAction('address/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const fetchSatelliteImageAction = createAction('address/satellite/fetch', function prepare(place_id: string) {
    return {
        payload: {
            place_id
        },
    }
}) as any
fetchSatelliteImageAction.pending = createAction('address/satellite/fetch/pending')
fetchSatelliteImageAction.fulfilled = createAction('address/satellite/fetch/fulfilled', function prepare(satelliteImage: any, zoomLevel: number, fetchedDate: string) {
    return {
        payload: {
            satelliteImage,
            zoomLevel,
            fetchedDate
        }
    }
})
fetchSatelliteImageAction.rejected = createAction('address/satellite/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const fetchStreetViewImagesAction = createAction('address/street-views/fetch', function prepare(place_id: string) {
    return {
        payload: {
            place_id
        },
    }
}) as any
fetchStreetViewImagesAction.pending = createAction('address/street-views/fetch/pending')
fetchStreetViewImagesAction.fulfilled = createAction('address/street-views/fetch/fulfilled', function prepare(streetViewImages: any) {
    return {
        payload: {
            streetViewImages
        }
    }
})
fetchStreetViewImagesAction.rejected = createAction('address/street-views/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

// export const fetcHistoricMonumentsAction = createAction('address/historic-momument/fetch', function prepare(place_id: string) {
//     return {
//         payload: {
//             place_id
//         },
//     }
// }) as any
// fetcHistoricMonumentsAction.pending = createAction('address/historic-momument/fetch/pending')
// fetcHistoricMonumentsAction.fulfilled = createAction('address/historic-momument/fetch/fulfilled', function prepare(historicMonuments: any) {
//     return {
//         payload: {
//             historicMonuments
//         }
//     }
// })
// fetcHistoricMonumentsAction.rejected = createAction('address/historic-momument/fetch/rejected', function prepare(error: any) {
//     return {
//         payload: {
//             error
//         }
//     }
// })

// export const fetchFireStationsAction = createAction('address/fire-station/fetch', function prepare(place_id: string) {
//     return {
//         payload: {
//             place_id
//         },
//     }
// }) as any
// fetchFireStationsAction.pending = createAction('address/fire-station/fetch/pending')
// fetchFireStationsAction.fulfilled = createAction('address/fire-station/fetch/fulfilled', function prepare(fireStations: any) {
//     return {
//         payload: {
//             fireStations
//         }
//     }
// })
// fetchFireStationsAction.rejected = createAction('address/fire-station/fetch/rejected', function prepare(error: any) {
//     return {
//         payload: {
//             error
//         }
//     }
// })


export const selectAutoComplete = (state: RootState) => state.address.autocomplete;
export const selectAddress = (state: RootState) => state.address.place;
export const selectSatelliteImage = (state: RootState) => state.address.satellite;
export const selectStreetViewImages = (state: RootState) => state.address.streetViews;
// export const selectHistoricMonuments = (state: RootState) => state.address.historicMonuments;
// export const selectFireStations = (state: RootState) => state.address.fireStations;

export default createReducer(initialState, (builder) => {
    builder.addCase(setAutoCompleteValue, (state, action) => {
        state.autocomplete = action.payload.place_id
    })
    // builder.addCase(fetchAddressAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchAddressAction.pending, (state, action) => {
        state.place.status = 'loading'
        state.place.error = undefined
    })
    builder.addCase(fetchAddressAction.fulfilled, (state, action) => {
        state.place.status = 'idle';
        state.place.value = action.payload.address
    })
    builder.addCase(fetchAddressAction.rejected, (state, action) => {
        state.place.status = 'failed';
        state.place.error = action.payload.error
    })

    // builder.addCase(fetchSateliteImageAction, (state, action) => {
    //     state.satellitImage = initialState.satellitImage
    // })
    builder.addCase(fetchSatelliteImageAction.pending, (state, action) => {
        state.satellite.status = 'loading'
    })
    builder.addCase(fetchSatelliteImageAction.fulfilled, (state, action) => {
        state.satellite.status = 'idle';
        state.satellite.value = action.payload
    })
    builder.addCase(fetchSatelliteImageAction.rejected, (state, action) => {
        state.satellite.status = 'failed';
        state.satellite.error = action.payload.error
    })

    // builder.addCase(fetchStreetViewImagesAction, (state, action) => {
    //     state.streetViewImages = initialState.streetViewImages
    // })
    builder.addCase(fetchStreetViewImagesAction.pending, (state, action) => {
        state.streetViews.status = 'loading'
    })
    builder.addCase(fetchStreetViewImagesAction.fulfilled, (state, action) => {
        state.streetViews.status = 'idle';
        state.streetViews.value = action.payload.streetViewImages
    })
    builder.addCase(fetchStreetViewImagesAction.rejected, (state, action) => {
        state.streetViews.status = 'failed';
        state.streetViews.error = action.payload.error
    })

    // // builder.addCase(fetcHistoricMonumentsAction, (state, action) => {
    // //     state.historicMonuments = initialState.historicMonuments
    // // })
    // builder.addCase(fetcHistoricMonumentsAction.pending, (state, action) => {
    //     state.historicMonuments.status = 'loading'
    // })
    // builder.addCase(fetcHistoricMonumentsAction.fulfilled, (state, action) => {
    //     state.historicMonuments.status = 'idle';
    //     state.historicMonuments.value = action.payload.historicMonuments
    // })
    // builder.addCase(fetcHistoricMonumentsAction.rejected, (state, action) => {
    //     state.historicMonuments.status = 'failed';
    //     state.historicMonuments.error = action.payload.error
    // })

    // // builder.addCase(fetchStreetViewImagesAction, (state, action) => {
    // //     state.fireStations = initialState.fireStations
    // // })
    // builder.addCase(fetchFireStationsAction.pending, (state, action) => {
    //     state.fireStations.status = 'loading'
    // })
    // builder.addCase(fetchFireStationsAction.fulfilled, (state, action) => {
    //     state.fireStations.status = 'idle';
    //     state.fireStations.value = action.payload.fireStations
    // })
    // builder.addCase(fetchFireStationsAction.rejected, (state, action) => {
    //     state.fireStations.status = 'failed';
    //     state.fireStations.error = action.payload.error
    // })
})
