import "./datagrid.css";

export interface CustomNoRowsOverlayProps {
  message: string;
}

export default function CustomNoRowsOverlay({
  message,
}: CustomNoRowsOverlayProps) {
  return (
    <div className="overlayNorows">
      <div className="blocText">
        <span className="noRowText">{message}</span>
      </div>
    </div>
  );
}
