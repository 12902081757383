export const FireSvg = ({style, size}: any): JSX.Element => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...style, height: size, width: size}}>
        <g clipPath="url(#clip0_955_20736)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.6141 3.57715L25.2906 5.18288C29.0771 7.45457 31.4476 10.6862 32.6875 13.9902C33.1592 12.9254 33.7196 11.8965 34.2402 10.8553L36.3249 12.94C41.0043 17.6194 45.4473 24.6326 45.4473 31.4426C45.4473 40.5765 39.8164 46.366 30.8073 47.3671L27.8538 47.6952L28.4983 44.7942C29.0025 42.5247 29.0916 41.0406 28.9889 40.0021C28.8597 38.6941 28.2382 37.669 27.4664 36.6486C26.7258 35.6694 25.9937 34.6906 25.4077 33.6082C22.9943 35.5153 22.0594 37.2372 21.7611 38.6538C21.3788 40.4702 21.9204 42.3245 22.9106 44.3048L24.6573 47.7985L20.7752 47.3671C15.3014 46.7589 9.90824 43.1027 7.784 37.7653C5.56266 32.1838 7.13343 25.428 14.306 19.2118C19.0667 15.0859 21.5878 9.73754 22.6141 3.57715ZM25.4449 10.5915C23.8256 15.1373 20.7176 19.2791 17.0894 22.4234C10.903 27.7848 10.3488 32.7165 11.7328 36.1936C12.7642 38.7855 14.9895 40.9175 17.6264 42.1266C17.3467 40.7703 17.2802 39.3083 17.6023 37.7783C18.2939 34.4934 20.657 31.4152 25.1962 28.5781L27.4849 27.1477L28.3385 29.7081C29.0865 31.9524 30.6388 33.5389 31.8509 35.5085C33.1854 37.6771 33.4406 40.1621 33.1711 42.6464C38.7346 41.2518 41.1973 37.03 41.1973 31.4426C41.1973 26.7215 38.3496 22.0887 35.522 18.4676C34.3599 21.0768 31.9038 21.9965 29.5098 23.1933V19.7551C29.5098 16.8461 28.3268 13.3001 25.4449 10.5915Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_955_20736">
                <rect width="51" height="51" fill="white" transform="translate(0.82251 0.629883)" />
            </clipPath>
        </defs>
    </svg>
);
