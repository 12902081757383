export async function segmentationImage(imageData: any, color: any) {
    const canvas = document.createElement('canvas')
    canvas.setAttribute('width', '640')
    canvas.setAttribute('height', '640')
    const ctx = canvas.getContext("2d")
    if (ctx) {
        ctx.fillStyle = "blue";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // first, create a new ImageData to contain our pixels
        const width = 640
        const height = 640
        let imgData = ctx.createImageData(width, height); // width x height


        for (let y = height - 1; y >=0 ; y--) {
            for (let x = width; x >= 0 ; x--) {
                const i = (y * width * 4) + (x * 4)
                const value = imageData[y][x]
                if (value === 0) {
                    imgData.data[i + 0] = 0     // R
                    imgData.data[i + 1] = 0     // G
                    imgData.data[i + 2] = 0     // B
                    imgData.data[i + 3] = 0     // A

                }
                else {
                    imgData.data[i + 0] = color.r   // R
                    imgData.data[i + 1] = color.g   // G
                    imgData.data[i + 2] = color.b   // B
                    imgData.data[i + 3] = color.a   // A
                }
            }
        }

        // now we can draw our imagedata onto the canvas
        ctx.putImageData(imgData, 0, 0);

        return new Promise(function(resolve, reject) {
            canvas.toBlob((blob) => {
                if (blob) {
                    let URLObj = window.URL || window.webkitURL;
                    const imageURL = URLObj.createObjectURL(blob)
                    resolve(imageURL)
                } else {
                    reject(null)
                }
            })
        })
    } else {
        return (null)
    }
}

export async function regionImage(coordinates: any, size: any, color: any) {
    if (!coordinates || !coordinates.length) { return (null) }
    const canvas = document.createElement('canvas')
    canvas.setAttribute('width', size.width);
    canvas.setAttribute('height', size.height);
    const ctx = canvas.getContext("2d")
    if (ctx) {
        ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
        ctx.strokeStyle = "#0000ff";
        ctx.lineWidth = 3;
        for (const rect of coordinates) {
            if (Array.isArray(rect[1])) {
                // ctx.fillRect(rect[0][0], rect[0][1], 30, 30)
                // ctx.fillRect(rect[1][0], rect[1][1], 30, 30)
                ctx.fillRect(rect[0][0], rect[0][1], (rect[1][0] - rect[0][0]), (rect[1][1] - rect[0][1]))
                // ctx.strokeRect(rect[0][0], rect[0][1], (rect[1][0] - rect[0][0]), (rect[1][1] - rect[0][1]))
            } else {
                ctx.fillRect(rect[0][0], rect[0][1], rect[1], rect[2])
                // ctx.strokeRect(rect[0][0], rect[0][1], rect[1], rect[2])
                // ctx.fillRect(rect[0][0], rect[0][1], 20, 20)
            }
        }

        return new Promise(function(resolve, reject) {
            canvas.toBlob((blob) => {
                if (blob) {
                    let URLObj = window.URL || window.webkitURL;
                    const imageURL = URLObj.createObjectURL(blob)
                    resolve(imageURL)
                } else {
                    reject(null)
                }
            })
        })
    } else {
        return (null)
    }
}