import { createAction, createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AIDetection {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

export interface AIDetectionSelection {
    solarPanel: boolean,
    pool: boolean,
    roof: boolean,
    graffiti: boolean,
    walledWindow: boolean,
    [key: string]: boolean
}

export interface AIDetectionState {
    selection: AIDetectionSelection,
    roof: AIDetection,
    pool: AIDetection,
    buildingAge: AIDetection,
    graffiti: AIDetection,
    solarPanel: AIDetection,
    walledWindow: AIDetection,
    [key: string]: AIDetection | AIDetectionSelection
}

const modelInitialState: AIDetection = {
    status: 'idle',
    value: undefined,
    error: undefined
}

const initialState: AIDetectionState = {
    selection: {
        solarPanel: false,
        pool: false,
        roof: false,
        graffiti: false,
        walledWindow: false,
    },
    roof: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    pool: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    buildingAge: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    graffiti: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    solarPanel: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    walledWindow: {
        status: 'idle',
        value: undefined,
        error: undefined
    }
}

function createActionCreator(type: string) {
    let actionCreator = createAction(`${type}/fetch`) as any // to allow adding properties
    actionCreator.pending = createAction(`${type}/fetch/pending`)
    actionCreator.fulfilled = createAction(`${type}/fetch/fulfilled`, function prepare(aiData: any) {
        return {
            payload: {
                aiData
            }
        }
    })
    actionCreator.rejected = createAction(`${type}/fetch/rejected`, function prepare(error: any) {
        return {
            payload: {
                error
            }
        }
    })

    return actionCreator
}

export const setAiModelSelection = createAction('model/selection', function prepare(model: string, value: boolean) {
    return {
        payload: {
            model,
            value
        },
    }
}) as any

export const resetAction = createAction(`ai/reset`)

export const fetchRoofModelDataAction = createActionCreator('roof')
export const fetchPoolModelDataAction = createActionCreator('pool')
export const fetchSolarPanelModelDataAction = createActionCreator('solar-panel')
export const fetchGraffitiModelDataAction = createActionCreator('graffiti')
export const fetchBuildingAgeModelDataAction = createActionCreator('building-age')
export const fetchWalledWindowModelDataAction = createActionCreator('walled-window')

export const selectAI = (state: RootState) => state.ai;
export const selectAIModelSelection = (state: RootState) => state.ai.selection;
export const selectRoofModelData = (state: RootState) => state.ai.roof;
export const selectPoolModelData = (state: RootState) => state.ai.pool;
export const selectGraffitiModelData = (state: RootState) => state.ai.graffiti;
export const selectSolarPanelModellData = (state: RootState) => state.ai.solarPanel;
export const selectBuildingAgeModelData = (state: RootState) => state.ai.buildingAge;
export const selectWalledWindowModelData = (state: RootState) => state.ai.walledWindow;

function addBuilderCases(builder: ActionReducerMapBuilder<AIDetectionState>, actionCreator: any, property: string) {
    // builder.addCase(actionCreator, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(actionCreator.pending, (state, action) => {
        state[property].status = 'loading'
        state[property].error = undefined
    })
    builder.addCase(actionCreator.fulfilled, (state, action) => {
        state[property].value = action.payload.aiData
        state[property].status = 'idle';
    })
    builder.addCase(actionCreator.rejected, (state, action) => {
        state[property].error = action.payload.error
        state[property].status = 'failed';
    })
}
export default createReducer(initialState, (builder) => {
    addBuilderCases(builder, fetchRoofModelDataAction, 'roof')
    addBuilderCases(builder, fetchPoolModelDataAction, 'pool')
    addBuilderCases(builder, fetchSolarPanelModelDataAction, 'solarPanel')
    addBuilderCases(builder, fetchGraffitiModelDataAction, 'graffiti')
    addBuilderCases(builder, fetchBuildingAgeModelDataAction, 'buildingAge')
    addBuilderCases(builder, fetchWalledWindowModelDataAction, 'walledWindow')
    builder.addCase(resetAction, (state, action) => {
        state.roof = modelInitialState
        state.pool = modelInitialState
        state.graffiti = modelInitialState
        state.walledWindow = modelInitialState
        state.buildingAge = modelInitialState
        state.solarPanel = modelInitialState
    })
    builder.addCase(setAiModelSelection, (state, action) => {
        const model = action.payload.model as string
        const value = action.payload.value as boolean
        state.selection[model] = value
    })
})
