import React, {
  useState /*, useEffect, useReducer, useCallback*/,
} from "react";
import { components } from "react-select";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const customStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
  }),
  // control: (provided: any, state: any) => ({
  //     paddingLeft: "12px",
  //     paddingRight: "12px",
  // })
};

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

export default function PlacesAutocomplete({ initialValue, onChange }: any) {
  const [value, setValue] = useState<any>();
  const [inputValue, setInputValue] = useState<any>(initialValue);

  const onInputChange = (inputValue: any, { action }: any) => {
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const handleChange = (option: any) => {
    setValue(option);
    onChange(option);
    setInputValue(option ? option.label : "");
  };

  return (
    <div style={{ width: "100%", display: "block" }}>
      <GooglePlacesAutocomplete
        apiOptions={{
          language: "fr",
          region: "fr",
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["FR", "GP", "MQ", "RE", "GF"], // limited to 5 country codes by Google place autocomplete api https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service?hl=fr#ComponentRestrictions
          },
        }}
        selectProps={{
          styles: customStyles,
          value: value,
          inputValue: inputValue,
          onInputChange: onInputChange,
          onChange: handleChange,
          controlShouldRenderValue: false,
          components: { Input },
          placeholder: "Rechercher une adresse",
          noOptionsMessage: () => "Pas de suggestions",
          loadingMessage: () => "chargement...",
          isClearable: true,
          blurInputOnSelect: true,
          openMenuOnClick: false,
        }}
      />
    </div>
  );
}
