import * as React from 'react';
import Icon from '../../components/Icon';
import { modelIconAlert } from '../../constants';
import { Typography } from '@mui/material';

export interface PopupInfoAlertProps {
    postal_code : string;
    type: string;
}

export default function PopupInfoAlert ({postal_code, type}: PopupInfoAlertProps) {
    const parseItemIcon = (type : string) => {
        return modelIconAlert.find(item => item.type === type)
    }
    return (
        <div className='popup'>
            <div className='popupText'>
                <Typography variant="h6">{parseItemIcon(type)?.title}</Typography>
                <Typography variant="subtitle2">{postal_code}</Typography>
            </div>
            <Icon type={`${parseItemIcon(type)?.iconType}_alert`} iconStyle={{height:'30px', width: '30px'}}/>
        </div>
    );
}
