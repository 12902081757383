import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import Icon from "../../components/Icon";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";

export interface IAuthInputProps {
  id: string;
  type: string;
  icon: string;
  name: string;
  label: string;
}

export default function AuthInput({
  id,
  type,
  icon,
  name,
  label,
}: IAuthInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Box width="320px" sx={{ marginTop: "20px" }}>
      {type === "password" ? (
        <TextField
          fullWidth
          required
          id={id}
          label={label}
          name={name}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box width="20px" justifyContent="center">
                  <Icon type={icon} />
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  sx={{ margin: 0 }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="standard"
          size="medium"
        />
      ) : (
        <TextField
          fullWidth
          required
          type={type}
          id={id}
          label={label}
          name={name}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon type={icon} />
              </InputAdornment>
            ),
          }}
          size="medium"
          sx={{ color: "#3C5278", "& label": { color: "#3C5278" } }} // Change the text and label color
        />
      )}
    </Box>
  );
}
