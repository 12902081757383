import React, {useState} from "react";
import Icon from "../Icon";
import DotsLoader from "../DotsLoader";
import "./index.css";
import { selectAddress } from "../../features/address/addressSlice";
import { useAppSelector } from "../../app/hooks";

interface PropsType {
    label: string;
    status: string;
    resultIsPresent: boolean | null;
    selected: boolean;
    isDisabled: boolean;
    iconType?: string | undefined;
    onVisibilityChange?: (visibility: boolean) => void;
    visible?: boolean;
    fetchModel?: () => void;
}

export default function AiModelResultInfos({
    label,
    status,
    resultIsPresent,
    selected,
    isDisabled,
    iconType,
    onVisibilityChange,
    visible,
    fetchModel,
}: PropsType): JSX.Element {
    const [visibility, setVisibility] = useState<boolean>(visible || true);
    const address = useAppSelector(selectAddress)

    const handleVisibilityChange = (visibility: boolean) => {
        setVisibility(visibility);
        onVisibilityChange && onVisibilityChange(visibility);
    };

    return (
        <div className="AiResultInfosContainer">
            <div className="MainContent">
                {iconType && <Icon type={iconType as string} containerStyle={{flex: 1, jusifyContent: "flex-start", paddingLeft: "2rem"}} />}
                <div className="LabelInfos">{label}</div>
                <div className="ResultInfos">
                    {status === "idle" && (resultIsPresent !== null ? resultIsPresent ? <div>OUI</div> : <div>NON</div> : <div>-</div>)}
                    {status === "loading" && <DotsLoader />}
                    {status === "failed" && (
                        <button className="RefreshButton" onClick={fetchModel}>
                            <Icon type="refresh" />
                        </button>
                    )}
                </div>
                <div className="DisplayResultButton">
                    {visibility || visible ? (
                        <button className="VisibilityIcon" onClick={() => handleVisibilityChange(false)} disabled={isDisabled}>
                            <Icon type="visibilityOff" />
                        </button>
                    ) : (
                        <button className="VisibilityIcon" onClick={() => handleVisibilityChange(true)} disabled={isDisabled}>
                            <Icon type="visibility" />
                        </button>
                    )}
                </div>
            </div>
            {!selected && address?.value && resultIsPresent === null && status === "idle" && (
                <div className="MiniText">
                    <span>Le modèle suivant n'a pas été sélectionné, voulez vous </span>
                    <button onClick={fetchModel}>l'activer</button>
                </div>
            )}
        </div>
    );
}
