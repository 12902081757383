import { call, put, takeLatest, all } from "redux-saga/effects";
import { getGeoJSONRisk } from "../address/data4homeAPI";
import {
  fetchZoneRiskDroughtAction,
  fetchZoneRiskHailStormAction,
  fetchZoneRiskFloodAction,
  fetchZoneRiskFireAction,
  fetchZoneRiskHeavyRainAction,
  fetchZoneRiskWindAction,
  fetchZoneRiskSubmersionAction,
  fetchZoneRiskCoastalErosionAction,
  fetchZoneRiskPluvialFloodAction,
  resetAction
} from "./riskGeojsonSlice";
import { fetchAddressAction } from '../address/addressSlice';

function generateSaga(actionCreator: any) {
  return function* (action: any): any {
    try {
      const { placeId, typeRisk } = action.payload;
      yield put(actionCreator.pending());
      const geoJSONRisk: any = yield call(getGeoJSONRisk, {
        placeId,
        typeRisk,
      });
      yield put(actionCreator.fulfilled(geoJSONRisk));
      return geoJSONRisk;
    } catch (e: any) {
      yield put(actionCreator.rejected(e.message));
    }
  };
}

const fetchDroughtModelData = generateSaga(fetchZoneRiskDroughtAction);
const fetchFloodModelData = generateSaga(fetchZoneRiskFloodAction);
const fetchFireModelData = generateSaga(fetchZoneRiskFireAction);
const fetchHeavyRainModelData = generateSaga(fetchZoneRiskHeavyRainAction);
const fetchWindModelData = generateSaga(fetchZoneRiskWindAction);
const fetchHailStormModelData = generateSaga(fetchZoneRiskHailStormAction);
const fetchSubmersionModelData = generateSaga(fetchZoneRiskSubmersionAction);
const fetchCoastalErosionModelData = generateSaga(fetchZoneRiskCoastalErosionAction);
const fetchPluvialFloodModelData = generateSaga(fetchZoneRiskPluvialFloodAction);

const resetZoneRiskState = function* (action: any) {
  yield put(resetAction())
}
function* zoneRiskSaga() {
  yield all([
      takeLatest(fetchZoneRiskDroughtAction, fetchDroughtModelData),
      takeLatest(fetchZoneRiskHeavyRainAction, fetchHeavyRainModelData),
      takeLatest(fetchZoneRiskFloodAction, fetchFloodModelData),
      takeLatest(fetchZoneRiskFireAction, fetchFireModelData),
      takeLatest(fetchZoneRiskHailStormAction, fetchHailStormModelData),
      takeLatest(fetchZoneRiskWindAction, fetchWindModelData),
      takeLatest(fetchZoneRiskSubmersionAction, fetchSubmersionModelData),
      takeLatest(fetchZoneRiskCoastalErosionAction, fetchCoastalErosionModelData),
      takeLatest(fetchZoneRiskPluvialFloodAction, fetchPluvialFloodModelData),
      takeLatest(fetchAddressAction.fulfilled, resetZoneRiskState),
  ])

}

export default zoneRiskSaga;
