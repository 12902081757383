/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { dataBool, dataCountry, dataLevel, dataPreviousLevel, modelIconAlert, objectValue, typeALertEditor } from "../../constants";
import moment from "moment";
import {parseISO} from "date-fns";
import { Button, Typography, styled } from "@mui/material";
import DatePicker, { registerLocale } from 'react-datepicker';
import { InputPicker, Input} from 'rsuite';
import _ from "lodash";
import {diff} from 'deep-diff';
import './index.css'
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateAlertAction, selectAlertEditor } from "../../features/alertEditorApi/alertEditorSlice";
import fr from "date-fns/locale/fr";

export interface AlertInputProps {
    alert?: any;
    type: string;
    onClose: () => void;
}

const ButtonBlue = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#1C5782',
    borderColor: '#1C5782',
    color: '#fff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
});

const ButtonCancel = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#282828',
    fontWeight: 600
});

registerLocale('fr', fr)
export default function AlertInput ({alert, type, onClose}: AlertInputProps) {
    const dispatch = useDispatch();
    const alertEditor = useSelector(selectAlertEditor);
    const [alertData, setAlertData] = useState(alert ? { ...alert, is_new: alert['is_new'].toString(), level_changed: alert['level_changed'].toString() } : objectValue[type]);
    const [startDate, setStartDate] = useState(
        (alert && alert.expected_at) ? moment(parseISO(alert.expected_at)).toDate() : moment().minutes(0).seconds(0).milliseconds(0).toDate()
    );
    const [endDate, setEndDate] = useState(
        (alert && alert.estimated_end) ? moment(parseISO(alert.estimated_end)).toDate() : new Date(moment(new Date()).add({hours: 7}).minutes(0).seconds(0).milliseconds(0).toDate())
    );
    const [riskDuration, setRiskDuration] = useState(
        (alert && alert.risk_duration) ? alert.risk_duration : moment(endDate).diff(moment(startDate), "hours")
    )
    const [minDate, setMinDate] = useState(
        alert ? moment(parseISO(alert.expected_at)).toDate() : new Date(moment(new Date()).minutes(0).seconds(0).milliseconds(0).toDate())
    );
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        setAlertData({...objectValue[type], type: type, risk_duration: riskDuration})
        setError('')
        setSuccess('')
    }, [type])

    useEffect(() => {
        const newDuration = moment(endDate).diff(moment(startDate), "hours")
        const alertDataCopy = { ...alertData };
        alertDataCopy.risk_duration = newDuration;
        setAlertData(alertDataCopy);
        setRiskDuration(newDuration)
    }, [startDate, endDate]);

    const handleChange = (text: string | Date, type: string) => {
        if (type === 'expected_at') {
            const alertDataCopy = { ...alertData };
            alertDataCopy[type] = moment(text).minutes(0).seconds(0).milliseconds(0).format();
            setStartDate(moment(text).toDate());
            setMinDate(moment(text).toDate())
            setError('');
            setAlertData(alertDataCopy);
        } else if (type === 'estimated_end') {
            const alertDataCopy = { ...alertData };
            alertDataCopy[type] = moment(text).minutes(0).seconds(0).milliseconds(0).format();
            setEndDate(moment(text).minutes(0).seconds(0).milliseconds(0).toDate());
            setError('');
            setAlertData(alertDataCopy);
        } else {
            const alertDataCopy = { ...alertData };
            alertDataCopy[type] = text;
            setError('');
            setAlertData(alertDataCopy);
        }
    };

    const handleError = () => {
        const keys = Object.keys(objectValue[type]);
        const keysInfo = keys ? keys.filter((x) => !['_id', 'country', 'temperatures', 'run_date'].includes(x)) : null;
        const simpleKeys = alert ? Object.keys(objectValue['SIMPLEPROP']) : Object.keys(objectValue['SIMPLE']);
        const arrayDiff = keysInfo ? keysInfo.filter((x) => !simpleKeys.includes(x)) : null;
        let error = '';

        if (type === 'SIMPLE') {
            error = "Vous devez choisir un type d'alerte !";
        } else if (!alert && keys.length !== Object.keys(alertData).length - 1) {
            error = "Vous devez remplir tous les champs !";
        } else if (alertData.postal_code?.length !== 5 || !Number.isInteger(parseInt(alertData.postal_code))) {
            error = "Code postal incorrect !";
        } else if (moment(endDate).isBefore(moment(startDate))) {
            error = "La date de fin doit être après à la date de début !";
        } else if (parseInt(alertData.risk_duration) === 0) {
            error = "La durée du risque doit être supérieure à 0 !";
        } else if (!Number.isInteger(alertData.risk_duration)) {
            error = "La durée du risque doit être un nombre !";
        } else if (alert && diff(alertData, alert) === undefined) {
            error = "Rien n'a été modifié !";
        } else if (arrayDiff) {
            arrayDiff.forEach((x) => {
                if (!parseInt(alertData[x])) {
                    error = `${x} doit être un nombre !`;
                }
            });
        }

        return error ? error : 1;
    };

    const extraInfo = _.filter(Object.keys(objectValue[type]), key => !Object.keys(objectValue['SIMPLE']).includes(key));

    const saveAlertChanges = () => {
        const res = handleError();
        if (res !== 1) {
            setError(res);
            setSuccess('');
            return;
        }
        const body = Object.keys(alertData).reduce((filteredData : any, key) => {
            if ((Object.keys(objectValue[type]).includes(key) || key === 'id')&&key!=='previous_level') {
                filteredData[key] = alertData[key];
            }
            return filteredData;
          }, {});
        if (!alertData.id) {
            dispatch(createOrUpdateAlertAction('ADD', body))
        } else {
            dispatch(createOrUpdateAlertAction('UPDATE', body))
        }
    }
    
    useEffect(() => {
        if (alertEditor.error) {
            setError(alertEditor.error)
            setSuccess('')
        } 
        if (alertEditor.status === "idle" && alertEditor.value) {
            setError('')
            setSuccess('New Alert Update !')
        }
    }, [alertEditor])


    const parseItemIcon = (type : any) => {
        return modelIconAlert.find(item => item.type === type)
    }

    return (
        <div>
            {!!alert && (
                <div>
                    <div>
                        <label>Alerte</label>
                        <InputPicker
                            data={typeALertEditor}
                            disabled
                            style={{ width: '100%'}}
                            menuStyle={{zIndex : 1350}}
                            defaultValue={typeALertEditor.find(alert => alert.value === type)?.label}
                            renderValue={(value, item, selectedElement) => {
                                const icon = parseItemIcon(type)?.iconType;
                                return (
                                    <div style={{display:'flex', alignItems: 'center'}}>                      
                                        {icon&&<Icon type={`${icon}_alert`} iconStyle={{height: '20px', width: '20px'}}/>}
                                        <Typography style={{paddingLeft: '10px', color: '#575757'}}>{value}</Typography>
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div>
                        <label>Id</label>
                        <Input 
                            disabled
                            type="text" 
                            value={alertData['id']} 
                            size="lg"
                        />
                    </div>
                </div>
            )}
            <div>
                <label>Niveau</label>
                <InputPicker
                    defaultValue={alert ? alert['level'] : 'ROUGE'}
                    data={dataLevel}
                    onChange={(e) => handleChange(e, 'level')}
                    menuStyle={{zIndex : 1350}}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </div>
            <div>
                <label>Niveau précédent</label>
                <InputPicker
                    defaultValue={alert ? alert['previous_level'] : 'INCONNU'}
                    data={dataPreviousLevel}
                    onChange={(e) => handleChange(e, 'previous_level')}
                    menuStyle={{zIndex : 1350}}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </div>
            <div id="parse-int">
                <label>Est nouvelle</label>
                <InputPicker
                    defaultValue={alert ? alert['is_new'].toString() : 'true'}
                    data={dataBool}
                    onChange={(e) => handleChange(e, 'is_new')}
                    menuStyle={{zIndex : 1350}}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </div>
            <div id="parse-int">
                <label>Niveau modifié</label>
                <InputPicker
                    defaultValue={alert ? alert['level_changed'].toString() : 'true'}
                    data={dataBool}
                    onChange={(e) => handleChange(e, 'level_changed')}
                    menuStyle={{zIndex : 1350}}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{ flexBasis: '47%' }}>
                    <label>Code postal</label>
                    <Input 
                        type="text" 
                        value={alertData['postal_code'] || ''} 
                        onChange={(e) => handleChange(e, 'postal_code')} 
                        size="lg"
                    />
                </div>
                <div style={{ flexBasis: '47%' }}>
                    <label>Pays</label>
                    <InputPicker
                        defaultValue={'FRANCE'}
                        data={dataCountry}
                        onChange={(e) => handleChange(e, 'country')}
                        menuStyle={{zIndex : 1350}}
                        size="lg"
                        style={{width: '100%'}}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{ flexBasis: '47%' }}>
                    <label>À</label>
                    <DatePicker
                        className="datePicker"
                        locale={'fr'}
                        selected={startDate}
                        onChange={(date : Date) => handleChange(date, 'expected_at')}
                        withPortal
                        selectsStart
                        showTimeSelect
                        minDate={minDate}
                        timeIntervals={60}
                        dateFormat="yyyy, MMMM, dd, HH:mm"
                    />
                </div>
                <div style={{ flexBasis: '47%' }}>
                    <label>Fin</label>
                    <DatePicker
                        className="datePicker"
                        locale={'fr'}
                        selected={endDate}
                        onChange={(date : Date) => {handleChange(date, 'estimated_end')}}
                        withPortal
                        selectsEnd
                        showTimeSelect
                        timeIntervals={60}
                        minDate={minDate}
                        dateFormat="yyyy, MMMM, dd, HH:mm"
                    />
                </div>
            </div>
            <div>
                <label>Durée du risque</label>
                <Input 
                    disabled 
                    id={'risk_duration'} 
                    value={riskDuration} 
                    size="lg"
                />
            </div>
            {extraInfo && extraInfo.map((data, index)=> (
                <div key={index}>
                <label>{data}</label>
                <Input type="text" value={alertData[data] || ''} onChange={(e) => handleChange(e, data)} />
              </div>
            ))}
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        {success ? (
          <Typography color="primary">{success}</Typography>
        ) : (
          <Typography color="error">{error}</Typography>
        )}
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <ButtonCancel variant="text" style={{ width: '30%', margin: 10 }} onClick={() => {onClose()}}>
                Annuler
            </ButtonCancel>
            <ButtonBlue style={{ width: '30%', margin: 10 }} onClick={saveAlertChanges}>
                Enregistrer
            </ButtonBlue>
        </div>
      </div>
   </div>
    );
}