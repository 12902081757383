import Icon from "../Icon";
import "./index.css";
import { useTranslation } from "react-i18next";

export default function RisksKey({ scaleLevel }: any): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="ScaleKeyWrapper">
      <div className="KeyTitle">Légende</div>
      <ul className="ScaleKeyitemsWrapper">
        {Object.keys(scaleLevel).map((key) => {
          const { color, label } = scaleLevel[key];
          if (label) {
            return (
              <li className="KeyItem" key={key}>
                <div
                  className="ColorItem"
                  style={{ backgroundColor: color }}
                ></div>
                <div className="LabelItem">{t(label)}</div>
              </li>
            );
          } else {
            return null;
          }
        })}
        <li className="KeyItem" key="warningIcon">
          <Icon type="warning" iconStyle={{ width: "16px", height: "16px" }} />
          <div className="LabelItem">{t("warning.catNat")}</div>
        </li>
      </ul>
    </div>
  );
}
