import instance from "../auth/authApi";

const API_BASE_URL = '/ai'

export async function getRoofDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/roof`, { params: { placeId } })
    return response.data
}

export async function getPoolDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/pool`, { params: { placeId } })
    return response.data
}

export async function getGraffitiDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/graffiti`, { params: { placeId } })
    return response.data
}

export async function getBuildingAgeDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/building-age`, { params: { placeId } })
    return response.data
}

export async function getSolarPanelDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/solar-panel`, { params: { placeId } })
    return response.data
}

export async function getWalledWindowDetectionModelData({ placeId }: any) {
    const response = await instance.get(`${API_BASE_URL}/walled-window`, { params: { placeId } })
    return response.data
}

