import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import moment from 'moment'

export interface WeatherRequestState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

export interface WeatherState {
    alert: WeatherRequestState,
    range: WeatherRequestState
}

const initialState: WeatherState = {
    alert: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    range: {
        status: 'idle',
        value: undefined,
        error: undefined
    }
}

export const fetchWeatherAction = createAction('weather/alert/fetch', function prepare({ date, postalCode }: { date: Date, postalCode: string }) {
    return {
        payload: { date: moment(date).format("YYYY-MM-DD"), postalCode },
    }
}) as any
fetchWeatherAction.pending = createAction('weather/alert/fetch/pending')
fetchWeatherAction.fulfilled = createAction('weather/alert/fetch/fulfilled', function prepare(alerts: any) {
    return {
        payload: {
            alerts
        }
    }
})
fetchWeatherAction.rejected = createAction('weather/alert/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const fetchWeatherRangeAction = createAction('weather/alert/range/fetch') as any
fetchWeatherRangeAction.pending = createAction('weather/alert/range/fetch/pending')
fetchWeatherRangeAction.fulfilled = createAction('weather/alert/range/fetch/fulfilled', function prepare(alertRange: any) {
    return {
        payload: {
            alertRange
        }
    }
})
fetchWeatherRangeAction.rejected = createAction('weather/alert/range/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const selectWeather = (state: RootState) => state.weather;
export const selectWeatherAlert = (state: RootState) => state.weather.alert;

export default createReducer(initialState, (builder) => {
    // builder.addCase(fetchWeatherAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchWeatherAction.pending, (state, action) => {
        state.alert.status = 'loading'
        state.alert.error = undefined
    })
    builder.addCase(fetchWeatherAction.fulfilled, (state, action) => {
        state.alert.status = 'idle';
        state.alert.value = action.payload.alerts
    })
    builder.addCase(fetchWeatherAction.rejected, (state, action) => {
        state.alert.status = 'failed';
        state.alert.error = action.payload.error
    })

    // builder.addCase(fetchWeatherRangeAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchWeatherRangeAction.pending, (state, action) => {
        state.range.status = 'loading'
        state.range.error = undefined
    })
    builder.addCase(fetchWeatherRangeAction.fulfilled, (state, action) => {
        state.range.status = 'idle';
        state.range.value = action.payload.alertRange
    })
    builder.addCase(fetchWeatherRangeAction.rejected, (state, action) => {
        state.range.status = 'failed';
        state.range.error = action.payload.error
    })
})
