export const TRANSLATIONS_EN = {
  11100: "Continuous Urban Fabric (S.L. > 80%)",
  11210: "Discontinuous Dense Urban Fabric (S.L. : 50% - 80%)",
  11220: "Discontinuous Medium Density Urban Fabric (S.L. : 30% - 50%)",
  11230: "Discontinuous Low Density Urban Fabric (S.L. : 10% - 30%)",
  11240: "Discontinuous Very Low Density Urban Fabric (S.L. < 10%)",
  11300: "Isolated Structures",
  12100: "Industrial, commercial, public, military and private units",
  12210: "Fast transit roads and associated land",
  12220: "Other roads and associated land",
  12230: "Railways and associated land",
  12300: "Port areas",
  12400: "Airports",
  13100: "Mineral extraction and dump sites",
  13300: "Construction sites",
  13400: "Land without current use",
  14100: "Green urban areas",
  14200: "Sports and leisure facilities",
  21000: "Arable land (annual crops)",
  22000: "Permanent crops (vineyards, fruit trees, olive groves)",
  23000: "Pastures",
  24000: "Complex and mixed cultivation patterns",
  25000: "Orchards at the fringe of urban classes",
  31000: "Forests",
  32000: "Herbaceous vegetation associations (natural grassland, moors...)",
  33000:
    "Open spaces with little or no vegetations (beaches, dunes, bare rocks, glaciers)",
  40000: "Wetland",
  50000: "Water bodies",
  noInfo: "No information",
  showListCatnat: "Show the complete list",
  hideListCatnat: "Hide the complete list",
  showCompleteList: "Here is the complete list of CATNAT",
  showLatestListByCategory:
    "Here is the list of the latest CATNAT by categories",
  address: "Address",
  startDate: "Date début arrêté",
  endDate: "Date fin arrêté",
  url: "Link URL",
  noPeril: "There are no peril notices near this address!",
  noCatnat: "There are no catnat notices near this address!",
  addPeril: "Add/Modify a peril",
  noSeveso: "There are no site seveso notices near this address!",
};
