import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface businessInRangeState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

export interface businessNafCodeState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

export interface businessState {
    within50m: businessInRangeState,
    nafCodes: businessNafCodeState
}

const initialState: businessState = {
    within50m: {
        status: 'idle',
        value: undefined,
        error: undefined
    },
    nafCodes: {
        status: 'idle',
        value: undefined,
        error: undefined
    }
}

export const fetchBusinessWithin50mAction = createAction('business/50/fetch', function prepare({lat, lng, nafCodeFilter, resultPerPage, page}: any) {
    return {
        payload: {lat, lng, nafCodeFilter, minDistance: undefined, maxDistance: 50, resultPerPage, page},
    }
}) as any
fetchBusinessWithin50mAction.pending = createAction('business/50/fetch/pending')
fetchBusinessWithin50mAction.fulfilled = createAction('business/50/fetch/fulfilled', function prepare(businesses: any) {
    return {
        payload: {
            businesses
        }
    }
})
fetchBusinessWithin50mAction.rejected = createAction('business/50/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const fetchBusinessNafCodesAction = createAction('business/nafCodes/fetch') as any
fetchBusinessNafCodesAction.pending = createAction('business/nafCodes/fetch/pending')
fetchBusinessNafCodesAction.fulfilled = createAction('business/nafCodes/fetch/fulfilled', function prepare(nafCodes: any) {
    return {
        payload: {
            nafCodes
        }
    }
})
fetchBusinessNafCodesAction.rejected = createAction('business/nafCodes/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const selectBusiness = (state: RootState) => state.business;
export const selectBusinessWithin50m = (state: RootState) => state.business.within50m;
export const selectBusinessNafCodes = (state: RootState) => state.business.nafCodes;

export default createReducer(initialState, (builder) => {
    // builder.addCase(fetchBusinessWithin50mAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchBusinessWithin50mAction.pending, (state, action) => {
        state.within50m.status = 'loading'
        state.within50m.error = undefined
    })
    builder.addCase(fetchBusinessWithin50mAction.fulfilled, (state, action) => {
        state.within50m.status = 'idle';
        state.within50m.value = action.payload.businesses
    })
    builder.addCase(fetchBusinessWithin50mAction.rejected, (state, action) => {
        state.within50m.status = 'failed';
        state.within50m.error = action.payload.error
    })

    // builder.addCase(fetchBusinessNafCodesAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchBusinessNafCodesAction.pending, (state, action) => {
        state.nafCodes.status = 'loading'
        state.nafCodes.error = undefined
    })
    builder.addCase(fetchBusinessNafCodesAction.fulfilled, (state, action) => {
        state.nafCodes.status = 'idle';
        state.nafCodes.value = action.payload.nafCodes
    })
    builder.addCase(fetchBusinessNafCodesAction.rejected, (state, action) => {
        state.nafCodes.status = 'failed';
        state.nafCodes.error = action.payload.error
    })
})
