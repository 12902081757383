import { call, put, takeLatest } from 'redux-saga/effects'
import {
    getClimateRisk
} from '../address/data4homeAPI'
import {
    fetchClimateRiskAction,
} from './climateRiskSlice'

function* fetchClimateRisk(action: any): any {
    try {
        yield put(fetchClimateRiskAction.pending())
        const alerts: any = yield call(getClimateRisk, action.payload.placeId)
        yield put(fetchClimateRiskAction.fulfilled(alerts))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchClimateRiskAction.rejected(error.message))
    }
}

function* climateRiskSaga() {
    yield takeLatest(fetchClimateRiskAction, fetchClimateRisk);
}

export default climateRiskSaga;