import React, { useEffect } from "react";
import { scaleLevels } from "../../constants";
// import { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import { WmsMapType } from '@googlemaps/ogc';
// import * as VectorTile from '@northpoint-labs/vector-tiles-google-maps'

export interface MapProps extends google.maps.MapOptions {
  mapId: string,
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  data4HomeInfo?: any;
  selectedRisk?: string;
  selectedRcp?: string;
  zoneRiskInfo?: any;
  initialCenter?: google.maps.LatLngLiteral;
  grayscale?: boolean
}

const Map: React.FC<MapProps> = ({
  mapId,
  initialCenter,
  onClick,
  onIdle,
  children,
  style,
  data4HomeInfo,
  selectedRisk,
  selectedRcp,
  zoneRiskInfo,
  grayscale = false,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  // const circles = React.useRef<google.maps.Marker[]>([]);
  const riskLayer =  React.useRef<google.maps.Data>()

  useEffect(() => {
    if (ref.current && !map) {
      const stylez = [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { saturation: -100 }
          ]
        }
      ];
      const map = new window.google.maps.Map(ref.current, {
        center: options.center,
        mapId, zoom: options.zoom,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'grayscale']
      }})
      const mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });
      map.mapTypes.set('grayscale', mapType);
      if (grayscale) {
        map.setMapTypeId('grayscale');
      }
      setMap(map);
      if (!(window as any).mapInstance) {
        (window as any).mapInstance = {}
      }
      (window as any).mapInstance[mapId] = map
    }
  }, [ref, map, grayscale, mapId, options]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useEffect(() => {
    let marker: any;
    if (map) {
      map.setOptions(options);
    }
    if (options.center) {
      marker = new google.maps.marker.AdvancedMarkerElement({
        position: options.center,
        map,
      });
    }
    return () => {
      marker && marker.setMap(null);
    };
  }, [map, options]);

  useEffect(() => {
    let buildingData: any, parcellData: any, bdnbData: any, volumes: any;
    if (map && data4HomeInfo) {
      // if (data4HomeInfo.empriseBatieParis && data4HomeInfo.empriseBatieParis.features && data4HomeInfo.empriseBatieParis.features.length > 0) {
      //     buildingData = new google.maps.Data()
      //     buildingData.addGeoJson(data4HomeInfo.empriseBatieParis)
      //     buildingData.setStyle({
      //         fillColor: "#770000",
      //         strokeColor: "#FF0000"
      //     })
      //     buildingData.setMap(map)
      // }

      if (
        data4HomeInfo.parcels &&
        data4HomeInfo.parcels.features &&
        data4HomeInfo.parcels.features.length > 0
      ) {
        parcellData = new google.maps.Data();
        parcellData.addGeoJson(data4HomeInfo.parcels);
        parcellData.setStyle({
          fillColor: "#007700",
          strokeColor: "#00FF00",
        });
        parcellData.setMap(map);
      }

      if (
        data4HomeInfo.buildings &&
        data4HomeInfo.buildings.features &&
        data4HomeInfo.buildings.features.length > 0
      ) {
        bdnbData = new google.maps.Data();
        bdnbData.addGeoJson(data4HomeInfo.buildings);
        bdnbData.setStyle({
          fillColor: "#000077",
          strokeColor: "#0000FF",
        });
        bdnbData.setMap(map);
      }

      return () => {
        buildingData && buildingData.setMap(null);
        parcellData && parcellData.setMap(null);
        bdnbData && bdnbData.setMap(null);
        volumes && volumes.setMap(null);
      };
    }
  }, [map, data4HomeInfo]);


  useEffect(() => {
    if (map && zoneRiskInfo && selectedRisk) {
      // const dataMapType = WmsMapType({
      //     url: "/wmts",
      //     layers: "data4home:drought",
      //     name: "Drought",
      //     alt: "Drought",
      //     maxZoom: 18
      // });

      // const options = {
      //   url: "/geoserver/gwc/service/tms/1.0.0/data4home%3Adrought@EPSG%3A4326@pbf/{z}/{x}/{y}.pbf"
      // };

      // const dataMapType = new VectorTile.MVTSource(map, options)

      // // alternative as overlay
      // map.overlayMapTypes.push(dataMapType);
      // return function() {
      //   map.overlayMapTypes.pop()
      // }
    }
  }, [map, zoneRiskInfo, selectedRisk, selectedRcp]);

  // useEffect(() => {
  //   if (map && zoneRiskInfo && selectedRisk) {
  //     const riskData = zoneRiskInfo[selectedRisk];
  //     if (riskData) {
  //       if (riskLayer.current) {
  //         riskLayer.current.setMap(null)
  //       }
  //       let riskDataLayer = new google.maps.Data();
  //       riskDataLayer.addGeoJson(riskData.value);
  //       riskDataLayer.setStyle(
  //         function(feature): google.maps.Data.StyleOptions {
  //           if (feature == null ) { return {} }

  //           let color
  //           if (["heavyRain", "wind"].includes(selectedRisk) && selectedRcp) {
  //             color = scaleLevels[feature.getProperty(selectedRcp) as number].color
  //           } else if (["submersion", "coastalErosion"].includes(selectedRisk)) {
  //             color = scaleLevels[(feature.getProperty("riskLevel") as number) - 1].color
  //           } else {
  //             color = scaleLevels[(feature.getProperty("riskLevel") as number)].color
  //           }
  //           var geometryType = feature.getGeometry()!.getType();
  //           if (geometryType === 'Point') {
  //             return {
  //               icon: {
  //                 path: google.maps.SymbolPath.CIRCLE,
  //                 scale: 5,  // Size of the red dot
  //                 fillColor: color,
  //                 fillOpacity: 1,
  //                 strokeWeight: 0
  //               }
  //             };
  //           }
  //           else {
  //             return {
  //               fillColor: color,
  //               strokeWeight: 0
  //             };
  //           }
  //         }
  //       );

  //       riskDataLayer.setMap(map);
  //       riskLayer.current = riskDataLayer
  //     }
  //   }
  // }, [map, zoneRiskInfo, selectedRisk, selectedRcp]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
    </>
  );
};

export default Map;
