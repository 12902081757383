import Icon from '../../components/Icon';
import { Checkbox } from '@mui/material';
import { AlertItemCheckBoxType } from '../../types';
import { useEffect, useState } from 'react';

export interface AlertItemCheckBoxProps {
    item: AlertItemCheckBoxType
    handleCheckboxChange: (layerId: string, isChecked: boolean) => void
}

export default function AlertItemCheckbox ({item, handleCheckboxChange} : AlertItemCheckBoxProps) {
  const [isChecked, setIsChecked] = useState<boolean>(item.isShow)

  useEffect(()=>{
    setIsChecked(item.isShow)
  },[item])

  const onCheckboxChange=()=>{
    const statusCheck = !isChecked;
    setIsChecked(statusCheck);
    handleCheckboxChange(item.id, statusCheck);
  }
  return (
    <label className='alertItem'>
        <Icon containerStyle={{flex: 1}} type={`${item.iconType}_alert`} iconStyle={{height:'30px', width: '30px'}}/>
        <span style={{flex: 4, fontSize: 13}}>{item.title}</span>
        <Checkbox style={{flex: 1}} name={item.iconType} size='small' checked={isChecked} onChange={onCheckboxChange}/>
    </label>
  );
}