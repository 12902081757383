import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";

import "./index.css";

export interface AppTheme {
  backgroundColor: {
    primary: string;
    secondary: string;
    grey: string;
  };
  color: {
    fontTitle: string;
    white: string;
  };
}

const theme: any = {
  backgroundColor: {
    primary: "#124376",
    secondary: "#D0D8E5",
    grey: "#F6F6F6",
  },
  color: {
    fontTitle: "#324A76",
    white: "#FFFFFF",
  },
};

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider<AppTheme> theme={theme}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
