import { call, put, takeLatest } from 'redux-saga/effects'
import {getAlertsForMap, getAlertsForMapDay} from '../address/data4homeAPI'
import {fetchAlertMapAction, fetchAlertMapDayAction} from './alertMapSlice'

function* fetchAlertMap(action: any): any {
    try {
        const { isFeatureCollection } = action.payload
        yield put(fetchAlertMapAction.pending())
        const alert: any = yield call(getAlertsForMap, {isFeatureCollection})
        yield put(fetchAlertMapAction.fulfilled(alert))
    } catch (exception) {
        yield put(fetchAlertMapAction.rejected(exception))
    }
}

function* fetchAlertMapDay(action: any): any {
    try {
        const { isFeatureCollection, startDate, endDate } = action.payload
        yield put(fetchAlertMapDayAction.pending())
        const alert: any = yield call(getAlertsForMapDay, {isFeatureCollection, startDate, endDate})
        yield put(fetchAlertMapDayAction.fulfilled(alert))
    } catch (exception) {
        yield put(fetchAlertMapDayAction.rejected(exception))
    }
}

function* alertMapSaga() {
    yield takeLatest(fetchAlertMapAction, fetchAlertMap);
    yield takeLatest(fetchAlertMapDayAction, fetchAlertMapDay);
}

export default alertMapSaga;