import { call, put, takeLatest } from 'redux-saga/effects'
import {
    getUserAnnotationForPlaceId,
    createOrUpdateUserAnnotationForPlaceId
} from '../address/data4homeAPI'
import {
    fetchUserAnnotationAction,
    createOrUpdateUserAnnotationAction
} from './userAnnotationSlice'

function* fetchUserAnnotation(action: any): any {
    try {
        const { placeId } = action.payload
        yield put(fetchUserAnnotationAction.pending())
        const businesses: any = yield call(getUserAnnotationForPlaceId, placeId)
        yield put(fetchUserAnnotationAction.fulfilled(businesses))
    } catch (exception: any) {
        yield put(fetchUserAnnotationAction.rejected(exception.message))
    }
}

function* createOrUpdateUserAnnotation(action: any): any {
    try {
        const { placeId, annotation } = action.payload
        yield put(createOrUpdateUserAnnotationAction.pending())
        const businessNafCodes: any = yield call(createOrUpdateUserAnnotationForPlaceId, {placeId, annotation})
        yield put(createOrUpdateUserAnnotationAction.fulfilled(businessNafCodes))
    } catch (exception: any) {
        yield put(createOrUpdateUserAnnotationAction.rejected(exception.message))
    }
}

function* adressSaga() {
    yield takeLatest(fetchUserAnnotationAction, fetchUserAnnotation);
    yield takeLatest(createOrUpdateUserAnnotationAction, createOrUpdateUserAnnotation);
}

export default adressSaga;