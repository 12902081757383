import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserInfoState {
  status: "idle" | "loading" | "failed";
  value?: any;
  error?: string;
}

const initialState: UserInfoState = {
  status: "idle",
  value: undefined,
  error: undefined,
};

export const fetchUserInfoAction = createAction("user/info/fetch") as any;
fetchUserInfoAction.pending = createAction("user/info/fetch/pending");
fetchUserInfoAction.fulfilled = createAction(
  "user/info/fetch/fulfilled",
  function prepare(userInfo: any) {
    return {
      payload: {
        userInfo,
      },
    };
  }
);
fetchUserInfoAction.rejected = createAction(
  "user/info/fetch/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const selectUserInfo = (state: RootState) => state.userInfo;

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchUserInfoAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(fetchUserInfoAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload.userInfo;
  });
  builder.addCase(fetchUserInfoAction.rejected, (state, action) => {
    state.status = "failed";
    state.value = undefined;
    state.error = action.payload.error;
  });
});
