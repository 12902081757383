import { call, put, takeLatest } from 'redux-saga/effects'
import {
    getWeatherAlert,
    getWeatherAlertRange
} from './data4homeAPI'
import {
    fetchWeatherAction,
    fetchWeatherRangeAction
} from './weatherSlice'

function* fetchWeatherAlert(action: any): any {
    try {
        yield put(fetchWeatherAction.pending())
        const alerts: any = yield call(getWeatherAlert, action.payload)
        yield put(fetchWeatherAction.fulfilled(alerts))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchWeatherAction.rejected(error.message))
    }
}

function* fetchWeatherAlertRange(action: any): any {
    try {
        yield put(fetchWeatherRangeAction.pending())
        const alerts: any = yield call(getWeatherAlertRange)
        yield put(fetchWeatherRangeAction.fulfilled(alerts))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchWeatherRangeAction.rejected(error.message))
    }
}

function* adressSaga() {
    yield takeLatest(fetchWeatherAction, fetchWeatherAlert);
    yield takeLatest(fetchWeatherRangeAction, fetchWeatherAlertRange);
}

export default adressSaga;