import { call, put, takeLatest } from 'redux-saga/effects'
import {
    getBusinesses,
    getBusinessNafCodes
} from './data4homeAPI'
import {
    fetchBusinessWithin50mAction,
    fetchBusinessNafCodesAction
} from './businessSlice'

function* fetchBusinesses(actionCreator: any, action: any): any {
    try {
        const {lat, lng, nafCodeFilter, minDistance, maxDistance, resultPerPage, page} = action.payload
        yield put(actionCreator.pending())
        const businesses: any = yield call(getBusinesses, {lat, lng, nafCodeFilter, minDistance, maxDistance, resultPerPage, page})
        yield put(actionCreator.fulfilled(businesses))
    } catch (exception) {
        yield put(actionCreator.rejected(exception))
    }
}

function* fetchBusinessNafCodes(action: any): any {
    try {
        yield put(fetchBusinessNafCodesAction.pending())
        const businessNafCodes: any = yield call(getBusinessNafCodes)
        yield put(fetchBusinessNafCodesAction.fulfilled(businessNafCodes))
    } catch (exception) {
        yield put(fetchBusinessNafCodesAction.rejected(exception))
    }
}

function* adressSaga() {
    yield takeLatest(fetchBusinessWithin50mAction, fetchBusinesses, fetchBusinessWithin50mAction);
    yield takeLatest(fetchBusinessNafCodesAction, fetchBusinessNafCodes, fetchBusinessWithin50mAction);
}

export default adressSaga;