import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    borderRadius: 15,
    border: '1px solid #aeaeae',
    width: '150px'
  },
  input: {
    border: 'none',
    textAlign: 'center',
    minWidth: 0,
    '&:disabled': {
      background: 'white',
    },
  },
});

interface CustomInputProps {
  disabled?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  value?: string;
  onClick?: () => void
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
  const { disabled, onNext, onPrev, value, onClick} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton disabled={disabled} onClick={onPrev} size='small'>
        <ChevronLeftIcon/>
      </IconButton>
      <input
        className={classes.input}
        disabled={disabled}
        style={{
          cursor: disabled ? 'not-allowed' : 'default',
          backgroundColor: 'white'
        }}
        type='button'
        value={value}
        onClick={onClick}
        ref={ref}
      />
      <IconButton disabled={disabled} onClick={onNext} size='small'>
        <ChevronRightIcon/>
      </IconButton>
      
    </div>
  );
});

CustomInput.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

export default CustomInput;
