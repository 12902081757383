export const DroughtSvg = ({style, size}: any): JSX.Element => (
    <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...style, height: size, width: size}}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2082 15.6633C31.2194 15.2459 32.3776 15.7274 32.795 16.7386C33.7521 19.0577 34.26 21.5375 34.2917 24.0461C34.2918 24.054 34.2919 24.0619 34.2919 24.0698C34.2936 26.7912 33.5946 29.4673 32.262 31.8401C30.9294 34.213 29.0082 36.2027 26.6834 37.6175C24.3587 39.0324 21.7088 39.8247 18.9889 39.9182C16.2691 40.0117 13.571 39.4032 11.1546 38.1514C10.1832 37.6481 9.80365 36.4527 10.3069 35.4812C10.8102 34.5098 12.0056 34.1303 12.977 34.6336C14.7894 35.5725 16.8129 36.0288 18.8528 35.9587C20.8927 35.8886 22.8801 35.2943 24.6237 34.2332C26.3672 33.172 27.8081 31.6798 28.8076 29.9001C29.8051 28.1239 30.3293 26.1211 30.33 24.0841C30.3032 22.0813 29.897 20.1017 29.1328 18.2501C28.7154 17.2388 29.1969 16.0807 30.2082 15.6633Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2929 0.668908C17.9956 0.166967 18.9425 0.178096 19.6332 0.696415C23.1611 3.34373 26.2956 6.47819 28.9429 10.0061C29.5995 10.8812 29.4225 12.1229 28.5474 12.7795C27.6723 13.4361 26.4307 13.2591 25.774 12.384C23.6525 9.55678 21.189 7.00569 18.4413 4.78833C18.1832 4.99777 17.8893 5.24178 17.5668 5.51821C16.3186 6.5881 14.6575 8.13116 13.0018 10.0233C9.63099 13.8757 6.55867 18.8609 6.55867 24.0711C6.55867 24.0739 6.55866 24.0768 6.55865 24.0796C6.54868 26.3993 7.2269 28.6699 8.50752 30.6041C9.11149 31.5163 8.86161 32.7454 7.9494 33.3494C7.03718 33.9534 5.80807 33.7035 5.2041 32.7913C3.49162 30.2048 2.58431 27.1688 2.59681 24.0669C2.59844 17.3932 6.4585 11.4849 10.0202 7.41443C11.8312 5.34475 13.6367 3.6688 14.9884 2.51014C15.6657 1.92961 16.2327 1.47584 16.6345 1.16429C16.8354 1.00845 16.9953 0.887976 17.1073 0.804814C17.1632 0.763226 17.2073 0.730948 17.2385 0.708222C17.2541 0.696858 17.2665 0.68788 17.2756 0.681318L17.2867 0.673291L17.2918 0.669661C17.2924 0.669266 17.2929 0.668908 18.4443 2.28086L17.2929 0.668908Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.6923 2.86109C36.4659 3.63469 36.4659 4.88894 35.6923 5.66255L3.99738 37.3575C3.22378 38.1311 1.96953 38.1311 1.19592 37.3575C0.422322 36.5838 0.422322 35.3296 1.19592 34.556L32.8908 2.86109C33.6644 2.08748 34.9187 2.08748 35.6923 2.86109Z"
            fill="white"
        />
    </svg>
);
