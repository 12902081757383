import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ClimateRiskRequestState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

const initialState: ClimateRiskRequestState = {
    status: 'idle',
    value: undefined,
    error: undefined
}

export const fetchClimateRiskAction = createAction('climate/risk/fetch', function prepare(placeId: string) {
    return {
        payload: {
            placeId
        }
    }
}) as any
fetchClimateRiskAction.pending = createAction('climate/risk/fetch/pending')
fetchClimateRiskAction.fulfilled = createAction('climate/risk/fetch/fulfilled', function prepare(risks: any) {
    return {
        payload: {
            risks
        }
    }
})
fetchClimateRiskAction.rejected = createAction('climate/risk/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})


export const selectClimateRisk = (state: RootState) => state.climateRisk;

export default createReducer(initialState, (builder) => {
    // builder.addCase(fetchClimateRiskAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchClimateRiskAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(fetchClimateRiskAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload
    })
    builder.addCase(fetchClimateRiskAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error
    })
})
