export const FloodSvg = ({style, size}: any): JSX.Element => (
    <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...style, height: size, width: size}}>
        <path
            d="M36.3604 34.1559C35.9229 34.1549 35.489 34.0761 35.0791 33.9232V33.9157C34.4786 33.6614 33.9359 33.288 33.4838 32.8181C33.0317 32.3482 32.6794 31.7916 32.4484 31.1818C32.3675 30.9251 32.2055 30.7016 31.9868 30.5448C31.7681 30.3881 31.5044 30.3064 31.2354 30.3122C30.9668 30.3135 30.7053 30.3986 30.4874 30.5556C30.2695 30.7126 30.1061 30.9337 30.0198 31.188C29.7362 32.0148 29.213 32.7385 28.5168 33.2671C27.8207 33.7956 26.9829 34.105 26.1104 34.1559C25.2367 34.1046 24.3981 33.7943 23.7014 33.2646C23.0048 32.735 22.4815 32.0099 22.1984 31.1818C22.1142 30.9271 21.9514 30.7058 21.7334 30.5495C21.5155 30.3933 21.2535 30.3102 20.9854 30.3122C20.7168 30.3135 20.4553 30.3986 20.2374 30.5556C20.0195 30.7126 19.8561 30.9337 19.7698 31.188C19.4862 32.0148 18.963 32.7385 18.2668 33.2671C17.5706 33.7956 16.7329 34.105 15.8604 34.1559C14.9867 34.1046 14.1481 33.7943 13.4514 33.2646C12.7548 32.735 12.2315 32.0099 11.9484 31.1818C11.8622 30.9277 11.6984 30.7072 11.4801 30.5512C11.2619 30.3952 11.0001 30.3117 10.7319 30.3124C10.4636 30.3131 10.2023 30.398 9.98487 30.5551C9.76741 30.7122 9.60475 30.9336 9.51983 31.188C9.23623 32.0148 8.71303 32.7385 8.01684 33.2671C7.32065 33.7956 6.48294 34.105 5.61035 34.1559H1.7666V36.7184H5.61035C6.6047 36.7272 7.58709 36.5012 8.47763 36.0588C9.36817 35.6163 10.1417 34.9699 10.7354 34.1722C11.3351 34.9634 12.1099 35.6051 12.999 36.0468C13.8882 36.4886 14.8675 36.7184 15.8604 36.7184C16.8532 36.7184 17.8325 36.4886 18.7217 36.0468C19.6108 35.6051 20.3856 34.9634 20.9854 34.1722C21.5851 34.9634 22.3599 35.6051 23.249 36.0468C24.1382 36.4886 25.1175 36.7184 26.1104 36.7184C27.1032 36.7184 28.0825 36.4886 28.9717 36.0468C29.8608 35.6051 30.6356 34.9634 31.2354 34.1722C31.9488 35.0942 32.894 35.8104 33.9745 36.2478C34.7309 36.5607 35.5418 36.7206 36.3604 36.7184H37.6416V34.1559H36.3604Z"
            fill="white"
        />
        <path
            d="M37.5282 17.4169L29.478 2.59942C29.3985 2.45557 29.2818 2.33567 29.1402 2.25217C28.9986 2.16867 28.8373 2.12463 28.6729 2.12463C28.5085 2.12463 28.3471 2.16867 28.2055 2.25217C28.0639 2.33567 27.9473 2.45557 27.8677 2.59942L19.8175 17.4169C19.741 17.5574 19.7023 17.7153 19.7052 17.8752C19.7082 18.0351 19.7526 18.1915 19.8342 18.329C19.9158 18.4665 20.0317 18.5805 20.1707 18.6597C20.3096 18.7389 20.4667 18.7807 20.6266 18.7808H36.7191C36.8791 18.7807 37.0362 18.7389 37.1751 18.6597C37.314 18.5805 37.43 18.4665 37.5116 18.329C37.5932 18.1915 37.6376 18.0351 37.6405 17.8752C37.6435 17.7153 37.6048 17.5574 37.5282 17.4169ZM27.712 7.24959H29.6338V12.3746H27.712V7.24959ZM28.6729 16.2183C28.4195 16.2183 28.1718 16.1432 27.9611 16.0024C27.7504 15.8616 27.5861 15.6615 27.4892 15.4274C27.3922 15.1933 27.3668 14.9357 27.4163 14.6871C27.4657 14.4386 27.5877 14.2103 27.7669 14.0311C27.9461 13.8519 28.1744 13.7299 28.4229 13.6805C28.6715 13.631 28.9291 13.6564 29.1632 13.7534C29.3973 13.8503 29.5974 14.0146 29.7382 14.2253C29.879 14.436 29.9541 14.6837 29.9541 14.9371C29.9541 15.2769 29.8192 15.6028 29.5789 15.8431C29.3386 16.0833 29.0127 16.2183 28.6729 16.2183Z"
            fill="white"
        />
        <path
            d="M35.0791 25.1871V21.3434H32.5166V25.1871H31.2354C30.3628 25.1362 29.5251 24.8268 28.8289 24.2983C28.1327 23.7698 27.6095 23.046 27.3259 22.2193C27.241 21.9648 27.0783 21.7434 26.8608 21.5863C26.6434 21.4292 26.3821 21.3443 26.1138 21.3436C25.8456 21.3429 25.5838 21.4264 25.3656 21.5824C25.1473 21.7384 24.9835 21.9589 24.8973 22.213C24.6142 23.0411 24.0909 23.7662 23.3943 24.2958C22.6976 24.8255 21.859 25.1358 20.9854 25.1871C20.1128 25.1362 19.2751 24.8268 18.5789 24.2983C17.8827 23.7698 17.3595 23.046 17.0759 22.2193C16.9896 21.9649 16.8262 21.7438 16.6083 21.5868C16.3904 21.4298 16.1289 21.3447 15.8604 21.3434C15.5922 21.3414 15.3302 21.4245 15.1123 21.5807C14.8943 21.737 14.7315 21.9583 14.6473 22.213C14.3642 23.0411 13.8409 23.7662 13.1443 24.2958C12.4476 24.8255 11.609 25.1358 10.7354 25.1871C9.89073 25.1485 9.07608 24.8625 8.39268 24.3647C7.70929 23.8668 7.1873 23.1791 6.8916 22.387V13.7347L19.7041 3.75375L22.9491 6.28499L24.1896 4.00206L20.4886 1.11745C20.2612 0.939854 19.981 0.843384 19.6925 0.843384C19.404 0.843384 19.1238 0.939854 18.8964 1.11745L0.485352 15.4745L2.07756 17.4884L4.3291 15.7329V22.3794C4.09208 22.955 3.7437 23.4782 3.30395 23.9188C2.8642 24.3594 2.34175 24.7088 1.7666 24.9469V27.6164C3.29956 27.3085 4.66682 26.4502 5.61035 25.2034C6.21008 25.9946 6.98487 26.6363 7.87402 27.078C8.76316 27.5198 9.74252 27.7497 10.7354 27.7497C11.7282 27.7497 12.7075 27.5198 13.5967 27.078C14.4858 26.6363 15.2606 25.9946 15.8604 25.2034C16.4601 25.9946 17.2349 26.6363 18.124 27.078C19.0132 27.5198 19.9925 27.7497 20.9854 27.7497C21.9782 27.7497 22.9575 27.5198 23.8467 27.078C24.7358 26.6363 25.5106 25.9946 26.1104 25.2034C26.704 26.0012 27.4775 26.6475 28.3681 27.09C29.2586 27.5324 30.241 27.7584 31.2354 27.7496H37.6416V25.1871H35.0791Z"
            fill="white"
        />
    </svg>
);
