import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import { AppTheme } from "..";
import { modelNotes } from "../constants";
import ButtonConfirm from "./ButtonConfirm/ButtonConfirm";

export interface AnswerList {
  [key: string]: boolean | null;
}

interface FromNotePropsType {
  questionList: AnswerList;
  address: any;
  onSaved: (answerList: AnswerList) => void;
}

export default function FromNote({
  questionList,
  address,
  onSaved,
}: FromNotePropsType): JSX.Element {
  // const keys : string[] = Object.keys(questionList);

  const [answerList, setAnswerList] = useState<AnswerList>(questionList);
  const [isDisabled, setIsDisable] = useState(true);
  const theme = useTheme<AppTheme>();

  useEffect(() => {
    setAnswerList(questionList);
  }, [questionList]);

  const handleAnswerChange = (question: string, answer: boolean) => {
    const updatedAnswers = { ...answerList };
    updatedAnswers[question] = answer;
    setAnswerList(updatedAnswers);
    setIsDisable(false);
  };

  const handleSave = () => {
    onSaved(answerList);
    setIsDisable(true);
  };

  return (
    <div>
      <h3 style={{ color: theme.color.fontTitle }}>Annotation d'utilisateur</h3>
      <Grid>
        {Object.keys(answerList).map((question, idx) => (
          <Grid key={idx}>
            <FormControl>
              <FormLabel id="controlled-radio-buttons-group">
                {modelNotes[question]}
              </FormLabel>
              <RadioGroup
                row
                name="radio-buttons-group"
                value={
                  answerList[question] === null
                    ? null
                    : answerList[question]
                    ? "yes"
                    : "no"
                }
                onChange={(e) =>
                  handleAnswerChange(question, e.target.value === "yes")
                }
              >
                <FormControlLabel
                  disabled={!address}
                  value="yes"
                  control={<Radio />}
                  label="Oui"
                />
                <FormControlLabel
                  disabled={!address}
                  value="no"
                  control={<Radio />}
                  label="Non"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        ))}
      </Grid>

      <ButtonConfirm
        disabled={isDisabled}
        title="Enregistrer"
        onClick={handleSave}
        style={{ justifyContent: "end" }}
      />
    </div>
  );
}
