import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAddress } from "../address/addressSlice";
import {
  fetchStreetViewImagesAction,
  selectStreetViewImages,
} from "../address/addressSlice";
import {
  selectGraffitiModelData,
  fetchGraffitiModelDataAction,
  selectWalledWindowModelData,
  fetchWalledWindowModelDataAction,
  selectAIModelSelection,
  setAiModelSelection,
} from "../address/aiDetectionSlice";
import {
  createOrUpdateUserAnnotationAction,
  fetchUserAnnotationAction,
  selectUserAnnotation,
} from "../userAnnotation/userAnnotationSlice";
import { regionImage } from "../address/CanvasService";
import SearchResultBlock from "../../components/Block";
import { CircularProgress } from "@mui/material";
import NoImages from "../../assets/no-images.png";
import Carousel from "../../components/Carousel";
import AiModelResultInfos from "../../components/AiModelResultInfos";
import { ResultBlockType } from "../../types";
import Grid from "@mui/material/Grid";
import FormNote, { AnswerList } from "../../components/FormNote";

export default function StreetViewSearchResultBlock({
  title,
  id,
  iconType,
}: ResultBlockType) {
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const streetViewImages = useAppSelector(selectStreetViewImages);
  const userAnnotation = useAppSelector(selectUserAnnotation);

  const [aiGraffitiImageURL, setAiGraffitimageURL] = useState<any>(null);
  const [aiWalledWindowImageURL, setAiIWalledWindowmageURL] =
    useState<any>(null);
  const [graffitiModelMaskVisibility, setGraffitiModelMaskVisibility] =
    useState<boolean>(false);
  const [walledWindowModelMaskVisibility, setWalledWindowModelMaskVisibility] =
    useState<boolean>(false);

  const graffiti = useAppSelector(selectGraffitiModelData);
  const walledWindow = useAppSelector(selectWalledWindowModelData);
  const aiModelSelection = useAppSelector(selectAIModelSelection);

  const [questionList, setQuestionList] = useState<AnswerList>({
    graffiti: null,
    walled_window: null,
    facade_crack: null,
  });

  useEffect(
    function () {
      if (address?.value)
        dispatch(fetchStreetViewImagesAction(address?.value?.placeId));
    },
    [dispatch, address?.value]
  );

  useEffect(
    function () {
      if (address?.value)
        dispatch(fetchUserAnnotationAction(address?.value?.placeId));
    },
    [dispatch, address?.value]
  );

  // The models request should happen after the address request data are available
  // The handling of the order is not obvious in the code. The goal is to make the model request only when new address data were fetched successfully
  // On the first address request success, the condition of this effect would be able to pass if models were selected
  // Then aiDetectionSaga will reset the ai state branch on subsequent address request success allowing the condition to be able to pass for the new address data
  const graffitySectionState = aiModelSelection["graffiti"];
  useEffect(
    function () {
      if (!streetViewImages.value) {
        return;
      }

      if (graffitySectionState) {
        dispatch(fetchGraffitiModelDataAction());
      }
    },
    [graffitySectionState, dispatch, streetViewImages.value]
  );

  const walledWindowSelectionState = aiModelSelection["walledWindow"];
  useEffect(
    function () {
      if (!streetViewImages.value) {
        return;
      }

      if (walledWindowSelectionState) {
        dispatch(fetchWalledWindowModelDataAction());
      }
    },
    [walledWindowSelectionState, dispatch, streetViewImages.value]
  );

  useEffect(
    function () {
      (async function () {
        if (!graffiti.value) {
          setAiGraffitimageURL(null);
          setGraffitiModelMaskVisibility(false);
          return;
        }
        const imageData = graffiti.value.boxes;
        const color = { r: 0, g: 255, b: 0, a: 255 };
        const imageURL = await regionImage(
          imageData,
          { width: "640", height: "640" },
          color
        );
        setAiGraffitimageURL(imageURL);
        setGraffitiModelMaskVisibility(true);
      })();
    },
    [graffiti]
  );

  useEffect(
    function () {
      (async function () {
        if (!walledWindow.value) {
          setAiIWalledWindowmageURL(null);
          setWalledWindowModelMaskVisibility(false);
          return;
        }
        const imageData = walledWindow.value.boxes;
        const color = { r: 0, g: 255, b: 0, a: 255 };
        const imageURL = await regionImage(
          imageData,
          { width: "640", height: "640" },
          color
        );
        setAiIWalledWindowmageURL(imageURL);
        setWalledWindowModelMaskVisibility(true);
      })();
    },
    [walledWindow]
  );

  const handleModelVisibility = (model: string, value: boolean) => {
    switch (model) {
      case "graffiti":
        setGraffitiModelMaskVisibility(value);
        break;
      case "walledWindow":
        setWalledWindowModelMaskVisibility(value);
        break;
      default:
    }
  };

  const handleFetchGraffitiModel = () => {
    dispatch(setAiModelSelection("graffiti", true));
    dispatch(
      fetchGraffitiModelDataAction({ placeId: address?.value?.placeId })
    );
  };

  const handleFetchWalledWindowModel = () => {
    dispatch(setAiModelSelection("walledWindow", true));
    dispatch(
      fetchWalledWindowModelDataAction({ placeId: address?.value?.placeId })
    );
  };

  const handleSaveAnswers = (updatedAnswers: AnswerList) => {
    setQuestionList(updatedAnswers);
    const body = {
      note: {
        street_view: updatedAnswers,
      },
    };
    dispatch(createOrUpdateUserAnnotationAction(address?.value?.placeId, body));
  };

  useEffect(() => {
    setQuestionList({
      graffiti:
        userAnnotation?.value?.note?.street_view.graffiti !== undefined
          ? userAnnotation?.value?.note?.street_view.graffiti
          : null,
      walled_window:
        userAnnotation?.value?.note?.street_view.walled_window !== undefined
          ? userAnnotation?.value?.note?.street_view.walled_window
          : null,
      facade_crack:
        userAnnotation?.value?.note?.street_view.walled_window !== undefined
          ? userAnnotation?.value?.note?.street_view.facade_crack
          : null,
    });
  }, [userAnnotation.value, address.value]);

  return (
    <SearchResultBlock
      title={title}
      style={{ display: "flex", flexDirection: "row" }}
      iconType={iconType}
      id={id}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={6}
          container
          alignSelf="center"
          justifyContent="center"
          className="PhotoItem"
        >
          {address.status === "loading" ||
          streetViewImages.status === "loading" ? (
            <div className="Loader">
              <CircularProgress />
            </div>
          ) : streetViewImages.status === "idle" && streetViewImages.value ? (
            <div className="StreetView">
              <Carousel>
                {streetViewImages.value.map(
                  (streetView: any, index: number) => (
                    <div
                      key={`streetview-${index}`}
                      className="StreetViewImage"
                    >
                      <img
                        key={`streetview-image-${index}`}
                        src={streetView}
                        alt="stree view"
                      />
                      {index === 0 &&
                        aiGraffitiImageURL &&
                        aiModelSelection.graffiti &&
                        graffitiModelMaskVisibility && (
                          <img
                            key={`streetview-mask-graffiti-${index}`}
                            className="StreetViewImageMask"
                            src={aiGraffitiImageURL}
                            alt="stree view"
                          />
                        )}
                      {index === 0 &&
                        aiWalledWindowImageURL &&
                        aiModelSelection.walledWindow &&
                        walledWindowModelMaskVisibility && (
                          <img
                            key={`streetview-mask-walled-window-${index}`}
                            className="StreetViewImageMask"
                            src={aiWalledWindowImageURL}
                            alt="stree view"
                          />
                        )}
                    </div>
                  )
                )}
              </Carousel>
            </div>
          ) : (
            <div className="PlaceHolder">
              <img src={NoImages} alt="street view" />
            </div>
          )}
        </Grid>
        <Grid item md={12} lg={6} alignSelf="flex-start">
          <ul>
            <li>
              <AiModelResultInfos
                label="Présence de graffiti"
                status={graffiti.status}
                resultIsPresent={
                  graffiti && graffiti.value && graffiti.value.boxes
                    ? graffiti.value.boxes.length > 0
                    : null
                }
                selected={graffitySectionState}
                isDisabled={
                  graffiti.value === undefined ||
                  (graffiti &&
                    graffiti.value &&
                    graffiti.value.boxes &&
                    graffiti.value.boxes.length === 0)
                }
                fetchModel={handleFetchGraffitiModel}
                onVisibilityChange={(visibility: boolean) =>
                  handleModelVisibility("graffiti", visibility)
                }
                visible={graffitiModelMaskVisibility}
                iconType="graffiti"
              />
            </li>
            <li>
              <AiModelResultInfos
                label="Présence de fenêtres murées"
                status={walledWindow.status}
                resultIsPresent={
                  walledWindow && walledWindow.value && walledWindow.value.boxes
                    ? walledWindow.value.boxes.length > 0
                    : null
                }
                selected={walledWindowSelectionState}
                isDisabled={
                  walledWindow.value === undefined ||
                  (walledWindow &&
                    walledWindow.value &&
                    walledWindow.value.boxes &&
                    walledWindow.value.boxes.length === 0)
                }
                fetchModel={handleFetchWalledWindowModel}
                onVisibilityChange={(visibility: boolean) =>
                  handleModelVisibility("walledWindow", visibility)
                }
                visible={walledWindowModelMaskVisibility}
                iconType="window"
              />
            </li>
            {/* <li>
                            <AiModelResultInfos
                                label="Présence de fissures"
                                status={"idle"}
                                resultIsPresent={null}
                                selected={false}
                                isDisabled={true}
                                iconType="crack"
                            />
                        </li> */}
          </ul>
          <div className="UserNotes">
            <FormNote
              questionList={questionList}
              address={address.value}
              onSaved={handleSaveAnswers}
            />
          </div>
        </Grid>
      </Grid>
    </SearchResultBlock>
  );
}
