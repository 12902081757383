import { styled } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import DataGridLoading from "./DataGridLoading";

interface DataGridComponentProps {
  rows: GridRowsProp;
  cols: GridColDef[];
  rowId?: string;
  textNorows?: string;
}

const StyledDataGrid = styled(DataGrid)(() => ({
  border: "solid 1px #eeeeee",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#ece9fd",
    width: "100%",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: 0,
    "&:focus": {
      outline: "transparent",
    },
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    justifyContent: "center",
  },

  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    width: "100%",
    justifyContent: "center",
    textTransform: "uppercase",
  },
  "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },

  "& .MuiDataGrid-iconButtonContainer button": {
    padding: "4px",
  },

  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
    minHeight: "45px !important",
    "&:focus": {
      outline: "transparent",
    },
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "& .MuiDataGrid-row:nth-child(odd):hover, & .MuiDataGrid-row:nth-child(odd)":
    {
      backgroundColor: "transparent",
    },
  "& .MuiDataGrid-row:nth-child(even):hover, .MuiDataGrid-row:nth-child(even)":
    {
      backgroundColor: "#f6f6f6",
    },
}));

const DataGridComponent = ({
  rows,
  cols,
  rowId = "id",
  textNorows = "No rows",
}: DataGridComponentProps) => {
  const gridHeight = rows.length > 0 ? "auto" : 300;
  return (
    <div
      className="dataGridContainer"
      style={{ height: gridHeight, width: "100%" }}
    >
      <StyledDataGrid
        disableColumnFilter
        hideFooterPagination
        disableRowSelectionOnClick
        disableColumnSelector
        hideFooter
        disableColumnMenu
        rows={rows}
        columns={cols}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        columnHeaderHeight={50}
        getRowId={(row) => row[rowId]}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay message={textNorows} />,
          loadingOverlay: DataGridLoading,
        }}
      />
    </div>
  );
};

export default DataGridComponent;
