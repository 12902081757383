import Icon from "../Icon";

interface PropsType {
    type: string;
    level: string | null;
    onAlertClick?: (type: string) => void;
    label: string;
    isDisabled: boolean;
}

const containerIconStyle: Record<string, string> = {
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    boxShadow: "0px 4px 16px 0px rgba(189, 189, 189, 1)",
    cursor: "pointer",
};

const scaleLevel: Record<string, string> = {
    JAUNE: "#ffd60a",
    ORANGE: "#FF9E00",
    ROUGE: "#EC0808",
};

export default function WeatherItem({type, level, label, isDisabled, onAlertClick}: PropsType): JSX.Element {
    const setBackgroundColor = (level: string | null, isDisable: boolean): string => {
        if (isDisable) {
            return "#cdcdcd";
        }
        return level ? scaleLevel[level] : "#007200";
    };

    return (
        <>
            <button
                className="IconButton"
                onClick={onAlertClick ? () => onAlertClick(type) : undefined}
                style={{backgroundColor: "transparent", border: "none"}}
            >
                <Icon
                    type={type}
                    containerStyle={{
                        ...containerIconStyle,
                        backgroundColor: setBackgroundColor(level, isDisabled),
                    }}
                />
            </button>
            <h5 style={{margin: ".5rem 0"}}>{label}</h5>
        </>
    );
}
