export const HailSvg = ({style, size}: any): JSX.Element => (
    <svg viewBox="1 2 29 29" fill="white" xmlns="http://www.w3.org/2000/svg" style={{...style, height: size, width: size}}>
        <circle cx="21.4995" cy="29.5" r="1.5"/>
        <circle cx="24.4995" cy="25.5" r="1.5"/>
        <circle cx="7.5" cy="25.5" r="1.5"/>
        <circle cx="4.4995" cy="29.5" r="1.5"/>
        <circle cx="10.4995" cy="29.5" r="1.5"/>
        <polygon points="15.868 30.496 14.132 29.504 17.276 24 11.277 24 16.132 15.504 17.868 16.496 14.723 22 20.724 22 15.868 30.496"/>
        <path d="M23.5,22H23V20h.5a4.4975,4.4975,0,0,0,.3564-8.981l-.8154-.0639-.0986-.812a6.9938,6.9938,0,0,0-13.8838,0l-.0991.812-.8155.0639A4.4975,4.4975,0,0,0,8.5,20H9v2H8.5A6.4973,6.4973,0,0,1,7.2,9.1362a8.9943,8.9943,0,0,1,17.6006,0A6.4974,6.4974,0,0,1,23.5,22Z"/>
    </svg>
);

