import React from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

function renderBusinesses(businesses: any, itemsPerPage: number) {
  let features: any[] = [];
  if (businesses.value && businesses.value.businesses) {
    features = [...businesses.value.businesses];
  }

  if (features.length < itemsPerPage) {
    for (let i = features.length; i < itemsPerPage; i++) {
      features.push({
        uniteLegale: {
          denominationUniteLegale: "",
          siren: "",
        },
        etablissement: {
          activitePrincipaleEtablissement: "",
        },
        nafCode: {
          label: "",
        },
      });
    }
  }

  // const sortedFeatures = features.sort((a, b) => {
  //   const codeNAFA = a.nafCode.code;
  //   const codeNAFB = b.nafCode.code;
  //   return codeNAFA?.localeCompare(codeNAFB);
  // });

  // const CodeRedNAF = ["70.22Z", "43.21A"]; // to be implemented with the right NAF codes

  // return sortedFeatures.map(function (business: any, index: number) {
  return features.map(function (business: any, index: number) {
    if (!business.uniteLegale) {
      return null;
    }
    // const isRedNAF = CodeRedNAF.includes(business.nafCode.code);
    return (
      <tr className="BusinessItem" key={`buisiness-${index}`}>
        <td className="BusinessItemRow BusisnessName">
          <span>
            {business.uniteLegale.denominationUniteLegale ||
              business.uniteLegale.nomUniteLegale}
          </span>
        </td>
        <td className="BusinessItemRow BusinessNAF">
          {/* <span style={{ color: isRedNAF ? "red" : "inherit" }}>
            {business.etablissement.activitePrincipaleEtablissement}
          </span> */}
          <span>{business.etablissement.activitePrincipaleEtablissement}</span>
        </td>
        <td className="BusinessItemRow BusinessActivity">
          {/* <span style={{ color: isRedNAF ? "red" : "inherit" }}>
            {business.nafCode.label}
          </span> */}
          <span>{business.nafCode.label}</span>
        </td>
        <td className="BusinessItemRow BusinessSiren">
          <span>{business.uniteLegale.siren}</span>
        </td>
        <td className="BusinessItemRow BusinessDistance">
          <span>
            {business.distance
              ? `${business.distance.calculated.toFixed(0)}m`
              : null}
          </span>
        </td>
      </tr>
    );
  });
}

function BusinessData({
  businesses,
  loading,
  itemsPerPage,
  onPageChange,
  ...other
}: any) {
  const itemCount = (businesses.value && businesses.value.totalCount) || 0;
  const pageCount = Math.ceil(itemCount / itemsPerPage);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  return (
    <>
      <table
        className="Businesses"
        style={{ width: "100%", marginTop: "10px" }}
      >
        <thead>
          <tr
            className="BusinessItem"
            key="business-head"
            style={{ textAlign: "left" }}
          >
            <th className="BusinessItemRow BusisnessName">
              <span>Nom</span>
            </th>
            <th className="BusinessItemRow BusisnessNAF">
              <span>Code NAF</span>
            </th>
            <th className="BusinessItemRow BusisnessActivity">
              <span>Activité</span>
            </th>
            <th className="BusinessItemRow BusisnessSiren">
              <span>Siren</span>
            </th>
            <th className="BusinessItemRow BusisnessDistance">
              <span>Distance</span>
            </th>
          </tr>
        </thead>
        <tbody style={{ color: loading ? "#ddd" : "inherit" }}>
          {renderBusinesses(businesses, itemsPerPage)}
        </tbody>
      </table>
      <Stack
        spacing={2}
        sx={{
          padding: 1,
          alignItems: "center",
        }}
      >
        <Pagination count={pageCount} onChange={handleChange} />
      </Stack>
    </>
  );
}

export default BusinessData;
