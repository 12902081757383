/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import SearchResultBlock from "../../components/Block";
import { useSnackbar } from "notistack";
import { ResultBlockType } from "../../types";
import { useTranslation } from "react-i18next";
import DataGridComponent from "../../components/DataGridComponent/DataGridComponent";
import { GridColDef } from "@mui/x-data-grid";
// import Icon from "../../components/Icon";
import { selectAddress, selectAutoComplete } from "../address/addressSlice";

import {
  // fetchClimateRiskAction,
  selectClimateRisk,
} from "../risk/climateRiskSlice";

import PlacesAutocomplete from "./PlacesAutocomplete";
// import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "@emotion/styled";
import { selectUserInfo } from "../userInfo/userInfoSlice";
import "./Search.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #CCCCCC",
  boxShadow: 24,
  p: 4,
  paddingBottom: "10px",
  borderRadius: "5px",
};

const ButtonBlue = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#1C5782",
  borderColor: "#1C5782",
  color: "#fff",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
});

const ButtonCancel = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  color: "#282828",
  fontWeight: 600,
  border: "1px solid #ABABAB",
});

export default function EventListBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  const { enqueueSnackbar } = useSnackbar();
  // TODO: typing
  const [eventList, setEventList] = useState<any[]>([]);
  const { t } = useTranslation();
  const climateRisks = useAppSelector(selectClimateRisk);
  const address = useAppSelector(selectAddress);
  const userInfo = useAppSelector(selectUserInfo);
  // const [editableRowIndex, setEditableRowIndex] = useState<string | null>(null);
  // const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
  //   new Date()
  // );
  // const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
  //   new Date()
  // );
  const [inseeCodeTextInput, setInseeCodeTextInput] = useState("");
  const [urlTextInput, setURLTextInput] = useState("");
  const [contentTypeInput, setContentTypeInput] = useState("");
  const googleAutocomplete = useAppSelector(selectAutoComplete);
  const [place, setPlace] = useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPlace(googleAutocomplete?.label);
    setInseeCodeTextInput("");
    setURLTextInput("");
    // setSelectedEndDate(new Date());
    // setSelectedStartDate(new Date());
    // setEditableRowIndex(null);
    setOpen(false);
  };

  useEffect(() => {
    setPlace(googleAutocomplete?.label);
  }, [googleAutocomplete]);

  useEffect(() => {
    if (address.error) {
      enqueueSnackbar("L'interrogation a échoué", {
        variant: "error",
      });
    }
    if (address.value && address.value.inseePostalCodeData) {
      // dispatch(
      //   fetchPerilAction(address?.value?.latitude, address?.value?.longitude)
      // );
      // TODO: add error boundary to avoid crashing the entire application
      setInseeCodeTextInput(address.value.inseePostalCodeData.codeInsee);
    }
  }, [address]);

  useEffect(() => {
    if (climateRisks.value && climateRisks.value.risks) {
      const { events } = climateRisks.value.risks
      const  mappedEvents = events.map((item: any, index: number) => ({
          id: index,
          ...item
      }));

      setEventList(mappedEvents);
    }
    // if (arretePeril?.value?.success) {
    //   dispatch(
    //     fetchPerilAction(address?.value?.latitude, address?.value?.longitude)
    //   );
    // }
  }, [climateRisks]);

  const isAdmin = userInfo.value && userInfo.value.groups && userInfo.value.groups.includes("/admin");

  // const handleDeleteArretPeril = (id: any) => {
  //   dispatch(deletePerilAction(id));
  // };

  const columns: GridColDef[] = [
    {
      field: "content_type",
      headerName: t("contentType"),
      flex: 1,
      sortable: false,
      renderCell: (params: any) => (
        <div style={{textTransform: "capitalize"}}>
          <span id={`${params?.row.id}`} style={{color: "inherit"}}>{params?.row.content_type_FR || t(`${params?.row.content_type}`)}</span>
        </div>
      )
    },
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "url",
      headerName: t("url"),
      flex: 2,
      minWidth: 80,
      sortable: false,
      renderCell: (params: any) => (
        <div>
          {params?.row.url.includes("://")
             ? <a href={params?.row.url} target="_blank" rel="noreferrer">{params?.row.url}</a>
             : params?.row.url
          }
        </div>
      )
    },
    {
      field: "risk_type",
      headerName: t("riskType"),
      flex: 1,
      minWidth: 80,
      sortable: false,
      renderCell: (params: any) => (
        <div>
          {params?.row.risk_type_FR || t(`eventRiskType.${params?.row.risk_type}`)}
        </div>
      )
    },
  ];

  const handleValider = () => {
    if (place) {
      // const newPeril = {
      //   address: place,
      //   startDate: moment.utc(selectedStartDate).toDate(),
      //   endDate: moment.utc(selectedEndDate).toDate(),
      //   url: urlTextInput,
      // };
      // if (editableRowIndex) {
      //   dispatch(updatePerilAction(editableRowIndex, { newPeril }));
      // } else {
      //   dispatch(createNewPerilAction({ newPeril }));
      // }
    }
    handleClose();
  };

  return (
    <SearchResultBlock
      style={{
        padding: "20px 20px 20px 20px",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <DataGridComponent
        rows={eventList}
        cols={columns}
        textNorows={t("noEvent")}
      />
      {isAdmin && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleOpen} disabled={!address.value}>
            <AddCircleIcon
              sx={{ color: address.value ? "#5387FF" : "rgba(0, 0, 0, 0.12)" }}
              fontSize="large"
            />
          </IconButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-add-peril"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                {t("addEvent")}
              </Typography>

              <div className="blocModal">
                <div className="blocModalAddress">
                  <PlacesAutocomplete
                    initialValue={place}
                    onChange={(value: any) => setPlace(value?.label)}
                  />
                </div>
                <div>{t('or')}</div>
                <input
                  style={{
                    height: "38px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 4,
                    paddingLeft: 8,
                  }}
                  placeholder={t('inseeCodeInputPlaceholder')}
                  value={inseeCodeTextInput}
                  onChange={(e) => setInseeCodeTextInput(e.target.value)}
                />
                <select onChange={(e) => setContentTypeInput(e.target.value)}>
                  <option disabled selected={contentTypeInput === ""}>{t('contentType')}</option>
                  <option value="decree" selected={contentTypeInput === "decree"}>{t('decree')}</option>
                </select>
                <input
                  style={{
                    height: "38px",
                    border: "1px solid #CCCCCC",
                    borderRadius: 4,
                    paddingLeft: 8,
                  }}
                  placeholder={t('urlInputPlaceholder')}
                  value={urlTextInput}
                  onChange={(e) => setURLTextInput(e.target.value)}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonCancel
                    variant="text"
                    style={{ width: "30%", margin: 10 }}
                    onClick={handleClose}
                  >
                    Annuler
                  </ButtonCancel>
                  <ButtonBlue
                    style={{ width: "30%", margin: 10 }}
                    onClick={handleValider}
                  >
                    Valider
                  </ButtonBlue>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </SearchResultBlock>
  );
}
