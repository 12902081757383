const getLocalRefreshToken = () => {
  const user = getUser();
  return user?.refresh_token;
};

const getLocalAccessToken = () => {
  const user = getUser();
  return user?.access_token;
};

const updateLocalAccessToken = (token: string): void => {
  const user = getUser();
  if (user) {
    user.access_token = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
};
const updateLocalRefreshToken = (token: string): void => {
  const user = getUser();
  if (user) {
    user.refresh_token = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
};

const getUser = () => {
  const userString = localStorage.getItem("user");
  if (!userString) {
    return null;
  }
  return JSON.parse(userString);
};

const setUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
