import mapboxgl from "mapbox-gl";
import { AlertItemCheckBoxType, InputSelectAlert } from "../types";
import moment from "moment";

export const modelIconAlert : AlertItemCheckBoxType[] =
[
    {
        id: 'pollution',
        title: 'POLLUTION',
        type: 'POLLUTION',
        iconType: 'polution',
        isShow: true
    },
    {
        id: 'coldWave',
        title: 'GEL',
        type: 'COLD_WAVE',
        iconType: 'cold',
        isShow: true
    },
    {
        id: 'simplehot',
        title: 'FORTE CHALEUR',
        type: 'SIMPLE_HEAT_WAVE',
        iconType: 'simplehot',
        isShow: true
    },
    {
        id: 'heatWave',
        title: 'CANICULE',
        type: 'HEAT_WAVE',
        iconType: 'hot',
        isShow: true
    },
    {
        id: 'snow',
        title: 'NEIGE',
        type: 'SNOW',
        iconType: 'snow',
        isShow: true
    },
    {
        id: 'crue',
        title: 'INNODATION',
        type: 'FLOOD',
        iconType: 'flood',
        isShow: true
    },
    {
        id: 'wind',
        title: 'VENT',
        type: 'WINDS',
        iconType: 'wind',
        isShow: true
    },
    {
        id: 'thunder',
        title: 'ORAGE',
        type: 'THUNDER',
        iconType: 'storm',
        isShow: true
    },
    {
        id: 'rain',
        title: 'FORTE PLUIE',
        type: 'HEAVY_RAIN',
        iconType: 'rain',
        isShow: true
    },
];

export const MBXTOKEN : string = 'pk.eyJ1Ijoiam9zZW5kIiwiYSI6ImNsZW9lcndmajAxbTczcm9kZDVmaHAwNmoifQ.uQpmWPQJPlSsAkNMrEnUiA';

export const modelAlertLayer : Record<string, mapboxgl.AnyLayer> = {
  pollutionLayer : {
    id: 'pollution',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "POLLUTION"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#5c5c5c',
        ['boolean', ['feature-state', 'click'], false],
        '#5c5c5c',
        '#767676'
      ]
    },
  },

  coldWaveLayer : {
    id: 'coldWave',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "COLD_WAVE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#31daff',
        ['boolean', ['feature-state', 'click'], false],
        '#31daff',
        '#64e3ff'
      ]
    },
  },

  simplehotLayer : {
    id: 'simplehot',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "SIMPLE_HEAT_WAVE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#6b0105',
        ['boolean', ['feature-state', 'click'], false],
        '#6b0105',
        '#9d0208'
      ]
    },
  },

  heatWaveLayer : {
    id: 'heatWave',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "HEAT_WAVE"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#cc3100',
        ['boolean', ['feature-state', 'click'], false],
        '#cc3100',
        '#ff3d00'
      ]
    },
  },

  snowLayer : {
    id: 'snow',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "SNOW" || "SNOWV2"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#c9c9c9',
        ['boolean', ['feature-state', 'click'], false],
        '#c9c9c9',
        '#ffffff'
      ]
    },
  },

  crueLayer : {
    id: 'crue',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "FLOOD"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#1157d3',
        ['boolean', ['feature-state', 'click'], false],
        '#1157d3',
        '#2970ee'
      ]
    },
  },

  windLayer : {
    id: 'wind',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "WINDS"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#0f6b00',
        ['boolean', ['feature-state', 'click'], false],
        '#0f6b00',
        '#169e00'
      ]
    },
  },

  stormLayer : {
    id: 'thunder',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "THUNDER"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#a41cff',
        ['boolean', ['feature-state', 'click'], false],
        '#a41cff',
        '#7113af'
      ]
    },
  },
  heavyRainLayer : {
    id: 'rain',
    type: 'fill',
    source: 'alerts',
    filter : [ "any",["==", ["get", "type"], "HEAVY_RAIN"]],
    paint: {
      "fill-outline-color": "white",
      "fill-color": [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#00b8b8',
        'aqua'
      ]
    },
  }
};

export const SORTED_SIMPLEPROPS : string[]= [
  'id',
  'level',
  'previous_level',
  'is_new',
  'level_changed',
  'risk_duration',
  'expected_at',
  'estimated_end'
]

export const typeALertEditor : InputSelectAlert[] = [ // data for type input select in alert editor
  {
    label: 'Polution',
    value: 'POLLUTION',
    role: 'Master'
  }, {
    label: 'Gel',
    value: 'COLD_WAVE',
    role: 'Master'
  }, {
    label: 'Forte chaleur',
    value: 'SIMPLE_HEAT_WAVE',
    role: 'Master'
  }, {
    label: 'Canicule',
    value: 'HEAT_WAVE',
    role: 'Master'
  }, {
    label: 'Snow',
    value: 'SNOW',
    role: 'Master'
  }, {
    label: 'Innondation',
    value: 'FLOOD',
    role: 'Master'
  }, {
    label: 'Vent',
    value: 'WINDS',
    role: 'Master'
  }, {
    label: 'Orage',
    value: 'THUNDER',
    role: 'Master'
  }, {
    label: 'Forte pluie',
    value: 'HEAVY_RAIN',
    role: 'Master'
  }
]

const sevenHours = moment(new Date()).add({ hours: 7 }).minutes(0).seconds(0).milliseconds(0).toDate();

export const objectValue : Record<string,any> = { // object value to get the property of each type alerts
  WINDS : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    high_wind_average : "",
    high_wind_gust : "",
  },
  THUNDER : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    thunder_winds_altitude: "",
    thunder_winds_ground: "",
  },
  HEAT_WAVE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    temperatures_min_heatwave : "",
    temperatures_max_heatwave : "",
  },
  SIMPLE_HEAT_WAVE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    temperatures_min_heatwave : "",
    temperatures_max_heatwave : "",
  },
  COLD_WAVE : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    temperatures_min_coldfront : "",
    temperatures_max_coldfront : "",
  },
  SNOW : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    country : 'FRANCE',
    snow_precipitation_low: "",
    snow_precipitation_high: ""
  },
  FLOOD : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
      estimated_end : moment(sevenHours).format(),
      level : "ROUGE",
      is_new : "true",
      level_changed : "true",
      postal_code : "",
      previous_level : "INCONNU",
      risk_duration : "",
      rain : "",
      country : 'FRANCE',
      section : ''
  },
  POLLUTION : {
    expected_at : moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "true",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    rain : "",
    country : 'FRANCE',
    polluants : ""
  },
  HEAVY_RAIN : {
    expected_at :  moment().minutes(0).seconds(0).milliseconds(0).format(),
    estimated_end : moment(sevenHours).format(),
    level : "ROUGE",
    is_new : "true",
    level_changed : "false",
    postal_code : "",
    previous_level : "INCONNU",
    risk_duration : "",
    rain : "",
    country : 'FRANCE'
  },
  SIMPLE : { // simple alert are commons property to all alerts
    expected_at : null,
    estimated_end : null,
    is_new : null,
    level : null,
    level_changed : null,
    postal_code : null,
    previous_level : null,
    risk_duration : null,
    country : null
  },
  SIMPLEPROP : {
    type : null,
    id : null,
    expected_at : null,
    estimated_end : null,
    is_new : null,
    level : null,
    level_changed : null,
    postal_code : null,
    previous_level : null,
    risk_duration : null,
  },
}

export const dataLevel = [ // data for level input select in Alert editor
    {
        label: 'YELLOW',
        value: 'JAUNE',
        role: 'Master'
    },
    {
        label: 'ORANGE',
        value: 'ORANGE',
        role: 'Master'
    },
    {
        label: 'RED',
        value: 'ROUGE',
        role: 'Master'
    }
];

export const dataCountry = [ // data for country input select in alert editor
    {
        label: 'FRANCE',
        value: 'FRANCE',
        role: 'Master'
    }
];

export const dataPreviousLevel = [ //  data for previous_level input select in alert editor
    {
        label: 'YELLOW',
        value: 'JAUNE',
        role: 'Master'
    },
    {
        label: 'ORANGE',
        value: 'ORANGE',
        role: 'Master'
    },
    {
        label: 'RED',
        value: 'ROUGE',
        role: 'Master'
    },
    {
        label: 'UNKNOWN',
        value: 'INCONNU',
        role: 'Master'
    }
];

export const dataBool = [ //input select boolean for is_new
    {
        label: 'true',
        value: 'true',
        role: 'Master'
    },
    {
        label: 'false',
        value: 'false',
        role: 'Master'
    }
];
