import { Box, Button } from "@mui/material";

export interface IAuthButtonProps {
  label: string;
  type: "submit" | "button" | "reset";
}

export default function AuthButton({ label, type }: IAuthButtonProps) {
  return (
    <Box width="320px" sx={{ marginBottom: "20px", marginTop: "40px" }}>
      <Button
        type={type}
        fullWidth
        variant="outlined"
        sx={{
          borderColor: "#3C5278",
          color: "#3C5278",
          borderRadius: "20px",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {label}
      </Button>
    </Box>
  );
}
