import {
  createAction,
  createReducer,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface RiskZone {
  status: "idle" | "loading" | "failed";
  value?: any;
  error?: string;
}

export interface RiskZoneState {
  drought: RiskZone;
  hailStorm: RiskZone;
  flood: RiskZone;
  fire: RiskZone;
  heavyRain: RiskZone;
  wind: RiskZone;
  submersion: RiskZone;
  coastalErosion: RiskZone;
  pluvialFlood: RiskZone;
  [key: string]: RiskZone;
}

const modelInitialState: RiskZone = {
  status: "idle",
  value: undefined,
  error: undefined,
};

const initialState: RiskZoneState = {
  drought: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  hailStorm: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  flood: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  fire: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  heavyRain: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  wind: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  submersion: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  coastalErosion: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
  pluvialFlood: {
    status: "idle",
    value: undefined,
    error: undefined,
  },
};

export function createActionCreatorForRisk(typeRisk: string) {
  let actionCreator = createAction(
    `risk/zone/${typeRisk}/geoJSON/fetch`,
    function prepare(placeId: string, typeRisk: string) {
      return {
        payload: {
          placeId,
          typeRisk,
        },
      };
    }
  ) as any;
  actionCreator.pending = createAction(
    `risk/zone/${typeRisk}/geoJSON/fetch/pending`
  );
  actionCreator.fulfilled = createAction(
    `risk/zone/${typeRisk}/geoJSON/fetch/fulfilled`,
    function prepare(risks_geojson: any) {
      return {
        payload: {
          risks_geojson,
        },
      };
    }
  );
  actionCreator.rejected = createAction(
    `risk/zone/${typeRisk}/geoJSON/fetch/rejected`,
    function prepare(error: any) {
      return {
        payload: {
          error,
        },
      };
    }
  );

  return actionCreator;
}

export const resetAction = createAction(`risk/zone/reset`);

export const fetchZoneRiskDroughtAction = createActionCreatorForRisk("drought");
export const fetchZoneRiskHailStormAction =
  createActionCreatorForRisk("hailStorm");
export const fetchZoneRiskFloodAction = createActionCreatorForRisk("flood");
export const fetchZoneRiskFireAction = createActionCreatorForRisk("fire");
export const fetchZoneRiskHeavyRainAction =
  createActionCreatorForRisk("heavyRain");
export const fetchZoneRiskWindAction = createActionCreatorForRisk("wind");
export const fetchZoneRiskSubmersionAction =
  createActionCreatorForRisk("submersion");
export const fetchZoneRiskCoastalErosionAction =
  createActionCreatorForRisk("coastalErosion");
export const fetchZoneRiskPluvialFloodAction =
  createActionCreatorForRisk("pluvialFlood");

export const selectZoneRisk = (state: RootState) => state.zoneRisk;
export const selectDroughtRisk = (state: RootState) => state.zoneRisk.drought;
export const selectHailStormRisk = (state: RootState) =>
  state.zoneRisk.hailStorm;
export const selectFloodRisk = (state: RootState) => state.zoneRisk.flood;
export const selectFireRisk = (state: RootState) => state.zoneRisk.fire;
export const selectHeavyRainRisk = (state: RootState) =>
  state.zoneRisk.heavyRain;
export const selectWindRisk = (state: RootState) => state.zoneRisk.wind;
export const selectSubmersionRisk = (state: RootState) =>
  state.zoneRisk.submersion;
export const selectCoastalErosionRisk = (state: RootState) =>
  state.zoneRisk.coastalErosion;
export const selectPluvialFloodRisk = (state: RootState) =>
  state.zoneRisk.pluvialFlood;

function addBuilderCases(
  builder: ActionReducerMapBuilder<RiskZoneState>,
  actionCreator: any,
  property: string
) {
  builder.addCase(actionCreator.pending, (state, action) => {
    state[property].status = "loading";
    state[property].error = undefined;
  });
  builder.addCase(actionCreator.fulfilled, (state, action) => {
    state[property].value = action.payload.risks_geojson;
    state[property].status = "idle";
  });
  builder.addCase(actionCreator.rejected, (state, action) => {
    state[property].error = action.payload.error;
    state[property].status = "failed";
  });
}

export default createReducer(initialState, (builder) => {
  addBuilderCases(builder, fetchZoneRiskDroughtAction, "drought");
  addBuilderCases(builder, fetchZoneRiskHailStormAction, "hailStorm");
  addBuilderCases(builder, fetchZoneRiskFloodAction, "flood");
  addBuilderCases(builder, fetchZoneRiskFireAction, "fire");
  addBuilderCases(builder, fetchZoneRiskHeavyRainAction, "heavyRain");
  addBuilderCases(builder, fetchZoneRiskWindAction, "wind");
  addBuilderCases(builder, fetchZoneRiskSubmersionAction, "submersion");
  addBuilderCases(builder, fetchZoneRiskCoastalErosionAction, "coastalErosion");
  addBuilderCases(builder, fetchZoneRiskPluvialFloodAction, "pluvialFlood");

  builder.addCase(resetAction, (state, action) => {
    state.drought = modelInitialState;
    state.hailStorm = modelInitialState;
    state.flood = modelInitialState;
    state.fire = modelInitialState;
    state.heavyRain = modelInitialState;
    state.wind = modelInitialState;
    state.submersion = modelInitialState;
    state.coastalErosion = modelInitialState;
    state.pluvialFlood = modelInitialState;
  });
});
