import "./datagrid.css";
import { CircularProgress } from "@mui/material";

const DataGridLoading = () => {
  return (
    <div className="loadingPageContainer">
      <CircularProgress
        className="loader"
        sx={{ color: "#124376" }}
        size={30}
      />
    </div>
  );
};

export default DataGridLoading;
