import { all } from "redux-saga/effects";
import adressSaga from "../features/address/addressSaga";
import aiDetectionSaga from "../features/address/aiDetectionSaga";
import businessSaga from "../features/address/businessSaga";
import weatherSaga from "../features/address/weatherSaga";
import climateRiskSaga from "../features/risk/climateRiskSaga";
import userAnnotationSaga from "../features/userAnnotation/userAnnotationSaga";
import alertMapSaga from "../features/alertMap/alertMapSaga";
import alertEditorSaga from "../features/alertEditorApi/alertEditorSaga";
import arretPerilSaga from "../features/arretPeril/arretPerilSaga";
import userInfoSaga from "../features/userInfo/userInfoSaga";
import sevesoSaga from "../features/seveso/sevesoSaga";
import zoneRiskSaga from "../features/riskGeojson/riskGeojsonSaga";

function* RootSaga() {
  yield all([
    adressSaga(),
    aiDetectionSaga(),
    businessSaga(),
    weatherSaga(),
    climateRiskSaga(),
    zoneRiskSaga(),
    userAnnotationSaga(),
    alertMapSaga(),
    alertEditorSaga(),
    arretPerilSaga(),
    userInfoSaga(),
    sevesoSaga(),
  ]);
}

export default RootSaga;
