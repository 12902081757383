import { useState, useEffect } from "react";
import SearchResultBlock from "../../components/Block";
import { selectAddress } from "../address/addressSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  fetchSatelliteImageAction,
  selectSatelliteImage,
} from "../address/addressSlice";
import {
  selectPoolModelData,
  // selectRoofModelData,
  selectSolarPanelModellData,
  fetchPoolModelDataAction,
  fetchSolarPanelModelDataAction,
  setAiModelSelection,
  selectAIModelSelection,
} from "../address/aiDetectionSlice";

import { segmentationImage, regionImage } from "../address/CanvasService";
import NoImages from "../../assets/no-images.png";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import AiModelResultInfos from "../../components/AiModelResultInfos";
import { ResultBlockType } from "../../types";
import FormNote, { AnswerList } from "../../components/FormNote";
import {
  createOrUpdateUserAnnotationAction,
  fetchUserAnnotationAction,
  selectUserAnnotation,
} from "../userAnnotation/userAnnotationSlice";

function getImageCorners(
  centerLongitude: number,
  centerLatitude: number,
  zoom: number,
  imageWidthPixels: number,
  imageHeightPixels: number
) {
  // Calculate scale
  const scale =
    (156543.03392 * Math.cos((centerLatitude * Math.PI) / 180)) / 2 ** zoom;

  // Calculate width and height in meters
  const widthMeters = scale * imageWidthPixels;
  const heightMeters = scale * imageHeightPixels;

  // Calculate relative coordinates of corners in meters
  const northMeters = heightMeters / 2;
  const southMeters = -heightMeters / 2;
  const eastMeters = widthMeters / 2;
  const westMeters = -widthMeters / 2;

  // Convert relative coordinates to absolute coordinates
  const northLatitude = centerLatitude + northMeters / 111319.9;
  const southLatitude = centerLatitude + southMeters / 111319.9;
  const eastLongitude =
    centerLongitude +
    eastMeters / (111319.9 * Math.cos((centerLatitude * Math.PI) / 180));
  const westLongitude =
    centerLongitude +
    westMeters / (111319.9 * Math.cos((centerLatitude * Math.PI) / 180));

  // Return coordinates of corners
  return { northLatitude, southLatitude, eastLongitude, westLongitude };
}

export default function SatelliteSearchResultBlock({
  title,
  id,
  iconType,
}: ResultBlockType) {
  const address = useAppSelector(selectAddress);
  const satelliteImage = useAppSelector(selectSatelliteImage);
  const dispatch = useAppDispatch();
  const userAnnotation = useAppSelector(selectUserAnnotation);

  // const [aiRoofImageURL, setAiRoofImageURL] = useState<any>(null);
  const [aiPoolImageURL, setAiIPoolmageURL] = useState<any>(null);
  const [aiSolarPanelImageURL, setAiISolarPanelmageURL] = useState<any>(null);
  const [imgGeoshapeUrl, setImgGeoshapeUrl] = useState<any>(null);

  const solarPanel = useAppSelector(selectSolarPanelModellData),
    pool = useAppSelector(selectPoolModelData),
    // roof = useAppSelector(selectRoofModelData),
    aiModelSelection = useAppSelector(selectAIModelSelection);
  const [solarPanelModelMaskVisibility, setSolarPanelModelMaskVisibility] =
    useState<boolean>(false);
  const [poolMaskVisibility, setPoolModelMaskVisibility] =
    useState<boolean>(false);

  const [questionList, setQuestionList] = useState<AnswerList>({
    pool: null,
    solar_panel: null,
    roof_condition: null,
  });

  useEffect(
    function () {
      if (address?.value)
        dispatch(fetchSatelliteImageAction(address?.value?.placeId));
    },
    [dispatch, address?.value]
  );

  useEffect(
    function () {
      if (address?.value)
        dispatch(fetchUserAnnotationAction(address?.value?.placeId));
    },
    [dispatch, address?.value]
  );

  // The models request should happen after the address request data are available
  // The handling of the order is not obvious in the code. The goal is to make the model request only when new address data were fetched successfully
  // On the first address request success, the condition of this effect would be able to pass if models were selected
  // Then aiDetectionSaga will reset the ai state branch on subsequent address request success allowing the condition to be able to pass for the new address data
  const solaPanelSelectionState = aiModelSelection["solarPanel"];
  useEffect(
    function () {
      if (!satelliteImage?.value) {
        return;
      }

      if (solaPanelSelectionState) {
        dispatch(fetchSolarPanelModelDataAction());
      }
    },
    [solaPanelSelectionState, dispatch, satelliteImage?.value]
  );

  const poolSelectionState = aiModelSelection["pool"];
  useEffect(
    function () {
      if (!satelliteImage?.value) {
        return;
      }

      if (poolSelectionState) {
        dispatch(fetchPoolModelDataAction());
      }
    },
    [poolSelectionState, dispatch, satelliteImage?.value]
  );

  useEffect(
    function () {
      (async function () {
        if (!pool.value) {
          setAiIPoolmageURL(null);
          setPoolModelMaskVisibility(false);
          return;
        }

        const imageData = pool.value.matrix;
        const color = { r: 255, g: 0, b: 0, a: 255 };
        const imageURL = await segmentationImage(imageData, color);
        setAiIPoolmageURL(imageURL);
        setPoolModelMaskVisibility(true);
      })();
    },
    [pool]
  );

  useEffect(
    function () {
      (async function () {
        if (!solarPanel.value) {
          setAiISolarPanelmageURL(null);
          setSolarPanelModelMaskVisibility(false);
          return;
        }

        const imageData = solarPanel.value.boxes;
        const color = { r: 255, g: 255, b: 0, a: 255 };
        const imageURL = await regionImage(
          imageData,
          { width: "640", height: "640" },
          color
        );
        setAiISolarPanelmageURL(imageURL);
        setSolarPanelModelMaskVisibility(true);
      })();
    },
    [solarPanel]
  );

  const handleModelVisibility = (model: string, value: boolean) => {
    switch (model) {
      case "pool":
        setPoolModelMaskVisibility(value);
        break;
      case "solarPanel":
        setSolarPanelModelMaskVisibility(value);
        break;
      default:
    }
  };

  const handleFetchPoolModel = () => {
    dispatch(setAiModelSelection("pool", true));
    dispatch(fetchPoolModelDataAction());
  };
  const handleFetchSolarPanelModel = () => {
    dispatch(setAiModelSelection("solarPanel", true));
    dispatch(fetchSolarPanelModelDataAction());
  };

  const drawPolygon = (img: HTMLImageElement) => {
    const { buildings, latitude: lat, longitude: lng } = address.value;
    const buildingCoords = buildings.features[0].geometry.coordinates.map(
      (building: any[]) => building[0]
    );
    const zoomLevel = satelliteImage.value.zoomLevel;

    const width = 640;
    const height = 640;
    const scale = 1;
    const zoom = zoomLevel;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    // Calculate the pixel coordinates of the corners
    const corners = getImageCorners(
      lng,
      lat,
      zoom,
      width / scale,
      height / scale
    );
    const { northLatitude, southLatitude, eastLongitude, westLongitude } =
      corners;

    // Calculate the latitude and Longitude length on the image
    const lng_range = eastLongitude - westLongitude;
    const lat_range = northLatitude - southLatitude;

    // Functions to convert gps coordinates to spacial coordinates
    const lngToX = (lng: number) => ((lng - westLongitude) * width) / lng_range;
    const latToY = (lat: number) =>
      ((lat - southLatitude) * height) / lat_range;
    const pixelCoords: any[] = [];

    for (const building of buildingCoords) {
      // pixelCoords.push([lngToX(x), height - latToY(y)]);

      const pixelBuilding = building.map(([x, y]: [number, number]) => [
        lngToX(x),
        height - latToY(y),
      ]);
      pixelCoords.push(pixelBuilding);
    }

    if (ctx) {
      // Draw the polygon
      for (const pixelBuilding of pixelCoords) {
        ctx.beginPath();
        ctx.moveTo(pixelBuilding[0][0], pixelBuilding[0][1]);
        for (let i = 1; i < pixelBuilding.length; i++) {
          ctx.lineTo(pixelBuilding[i][0], pixelBuilding[i][1]);
        }
        ctx.closePath();
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 5;
        ctx.stroke();
        ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
        ctx.fill();
      }
    }
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      const url = window.URL.createObjectURL(blob);
      setImgGeoshapeUrl(url);
    });
  };

  const handleSaveAnswers = (updatedAnswers: AnswerList) => {
    if (!address?.value?.placeId) {
      return;
    }
    setQuestionList(updatedAnswers);
    const body = {
      note: {
        satellite: updatedAnswers,
      },
    };
    dispatch(createOrUpdateUserAnnotationAction(address?.value?.placeId, body));
  };

  useEffect(() => {
    setQuestionList({
      pool:
        userAnnotation?.value?.note?.satellite.pool !== undefined
          ? userAnnotation?.value?.note?.satellite.pool
          : null,
      solar_panel:
        userAnnotation?.value?.note?.satellite.solar_panel !== undefined
          ? userAnnotation?.value?.note?.satellite.solar_panel
          : null,
      roof_condition:
        userAnnotation?.value?.note?.satellite.roof_condition !== undefined
          ? userAnnotation?.value?.note?.satellite.roof_condition
          : null,
    }); // reset answer when change address
  }, [address.value, userAnnotation.value]);

  return (
    <SearchResultBlock
      title={`${title}`}
      style={{ display: "flex", flexDirection: "row" }}
      iconType={iconType}
      id={id}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={6}
          container
          justifyContent="center"
          alignSelf="center"
          className="PhotoItem"
        >
          {address.status === "loading" ||
          satelliteImage.status === "loading" ? (
            <div className="Loader">
              <CircularProgress />
            </div>
          ) : satelliteImage.status === "idle" && satelliteImage.value ? (
            <div className="Satellite">
              <div key="satellite" className="SatelliteImage">
                <img
                  src={satelliteImage.value.satelliteImage}
                  alt="satellite"
                  onLoad={(e) => {
                    drawPolygon(e.target as HTMLImageElement);
                  }}
                />
                {imgGeoshapeUrl && (
                  <img
                    key={"satellite-geoshape"}
                    src={imgGeoshapeUrl}
                    className="SatelliteImageMask"
                    alt="satellite geoshape mask"
                  />
                )}
                {aiModelSelection.pool &&
                  aiPoolImageURL &&
                  poolMaskVisibility && (
                    <img
                      key="satellite-pool-mask"
                      src={aiPoolImageURL}
                      className="SatelliteImageMask"
                      alt="satellite pool mask"
                    />
                  )}
                {aiModelSelection.solarPanel &&
                  aiSolarPanelImageURL &&
                  solarPanelModelMaskVisibility && (
                    <img
                      key="satellite-solar-panel-mask"
                      src={aiSolarPanelImageURL}
                      className="SatelliteImageMask"
                      alt="satellite solar panel mask"
                    />
                  )}
              </div>
            </div>
          ) : (
            <div className="PlaceHolder">
              <img key="satellite" src={NoImages} alt="satellite" />
            </div>
          )}
        </Grid>
        <Grid item md={12} lg={6} alignSelf="flex-start">
          <ul>
            <li>
              <AiModelResultInfos
                label="Présence de piscine"
                status={pool.status}
                resultIsPresent={
                  pool && pool.value ? pool.value["mask_coverage"] > 0 : null
                }
                selected={poolSelectionState}
                isDisabled={
                  pool.value === undefined ||
                  (pool && pool.value && pool.value["mask_coverage"] === 0)
                }
                fetchModel={handleFetchPoolModel}
                onVisibilityChange={(visibility: boolean) =>
                  handleModelVisibility("pool", visibility)
                }
                visible={poolMaskVisibility}
                iconType="pool"
              />
            </li>
            <li>
              <AiModelResultInfos
                label="Présence de panneau solaire"
                status={solarPanel.status}
                resultIsPresent={
                  solarPanel && solarPanel.value
                    ? solarPanel.value.boxes.length > 0
                    : null
                }
                selected={solaPanelSelectionState}
                isDisabled={
                  solarPanel.value === undefined ||
                  (solarPanel &&
                    solarPanel.value &&
                    solarPanel.value.boxes.length === 0)
                }
                fetchModel={handleFetchSolarPanelModel}
                onVisibilityChange={(visibility: boolean) =>
                  handleModelVisibility("solarPanel", visibility)
                }
                visible={solarPanelModelMaskVisibility}
                iconType="solarPanel"
              />
            </li>
          </ul>
          <div className="UserNotes">
            <FormNote
              questionList={questionList}
              address={address.value}
              onSaved={handleSaveAnswers}
            />
          </div>
        </Grid>
      </Grid>
    </SearchResultBlock>
  );
}
