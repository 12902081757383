import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import Icon from "../../components/Icon";
import { FormEvent, useState } from "react";
import AuthService from "./authService";
import AuthInput from "./AuthInput";
import { useTranslation } from "react-i18next";
import AuthButton from "./AuthButton";
import "./Auth.css";
import Copyright from "./Copyright";
import AuthAlert from "./AuthAlert";
const theme = createTheme();

export default function ResetPassword() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { token } = useParams();
  const [message, setMessage] = useState(t("changePass"));
  const [typeAlert, setTypeAlert] = useState<"success" | "error" | "warning">(
    "warning"
  );
  const [signOutAll, setsignOutAll] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsignOutAll(event.target.checked);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const password = data.get("password")!.toString();
      const confirmPassword = data.get("confirmPassword")!.toString();
      const passwordPolicy =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (!passwordPolicy.test(password)) {
        setOpen(true);
        setMessage(t("passwordPolicyViolation"));
        setTypeAlert("error");
      } else if (password !== confirmPassword) {
        setOpen(true);
        setMessage(t("passwordNotMatch"));
        setTypeAlert("error");
      } else if (token) {
        const response = await AuthService.resetPassword(
          token,
          password,
          signOutAll
        );
        if (response.status === 200) {
          setOpen(true);
          setMessage(t("resetPasswordSuccess"));
          setTypeAlert("success");
          setTimeout(() => {
            navigate("/auth/signin");
          }, 1000);
        }
      }
    } catch (error: any) {
      console.log(error);
      setOpen(true);
      setMessage(error.response.data.error);
      setTypeAlert("error");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            width: "550px",
            height: "650px",
            justifyContent: "space-between",
          }}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Box margin="20px">
                <Icon type="logo_d4r" />
              </Box>
              <AuthAlert open={open} message={message} type={typeAlert} />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              display="flex"
              flexDirection="column"
              height={360}
            >
              <FormHelperText
                sx={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  width: 320,
                }}
              >
                {t("passwordRules")}
              </FormHelperText>
              <AuthInput
                id="password"
                type="password"
                icon="lock"
                name="password"
                label={t("newPassword")}
              />
              <AuthInput
                id="password"
                type="password"
                icon="lock"
                name="confirmPassword"
                label={t("confirmPassword")}
              />
              <Box display="flex" sx={{ marginTop: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={signOutAll}
                      onChange={handleCheckboxChange}
                      color="primary"
                      name="signOutAll"
                      size="small"
                    />
                  }
                  label={t("signOutAllDevices")}
                  sx={{ marginRight: 0, color: "#3C5278", fontSize: "12px" }}
                />
              </Box>
              <AuthButton type="submit" label={t("submit")} />
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
