import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserAnnotationState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

const initialState: UserAnnotationState = {
    status: 'idle',
    value: undefined,
    error: undefined
}

export const fetchUserAnnotationAction = createAction('user/annotation/fetch', function prepare(placeId: any) {
    return {
        payload: {
            placeId
        },
    }
}) as any
fetchUserAnnotationAction.pending = createAction('user/annotation/fetch/pending')
fetchUserAnnotationAction.fulfilled = createAction('user/annotation/fetch/fulfilled', function prepare(annotation: any) {
    return {
        payload: {
            annotation
        }
    }
})
fetchUserAnnotationAction.rejected = createAction('user/annotation/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const createOrUpdateUserAnnotationAction = createAction('user/annotation/create-or-update', function prepare(placeId: any, annotation: any) {
    return {
        payload: {
            placeId,
            annotation
        },
    }
}) as any
createOrUpdateUserAnnotationAction.pending = createAction('user/annotation/create-or-update/pending')
createOrUpdateUserAnnotationAction.fulfilled = createAction('user/annotation/create-or-update/fulfilled', function prepare(annotation: any) {
    return {
        payload: {
            annotation
        }
    }
})
createOrUpdateUserAnnotationAction.rejected = createAction('user/annotation/create-or-update/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const selectUserAnnotation = (state: RootState) => state.userAnnotation;

export default createReducer(initialState, (builder) => {
    // builder.addCase(fetchUserAnnotationAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(fetchUserAnnotationAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(fetchUserAnnotationAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.annotation
    })
    builder.addCase(fetchUserAnnotationAction.rejected, (state, action) => {
        state.status = 'failed';
        state.value = undefined
        state.error = action.payload.error
    })

    // builder.addCase(createOrUpdateUserAnnotationAction, (state, action) => {
    //     state = initialState
    // })
    builder.addCase(createOrUpdateUserAnnotationAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(createOrUpdateUserAnnotationAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.annotation
    })
    builder.addCase(createOrUpdateUserAnnotationAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error
    })
})
