import { Navigate } from "react-router-dom";
import TokenService from "./tokenService";

export default function ProtectedRoute({ children }: { children: any }) {
  const user = TokenService.getUser();
  if (!user) {
    return <Navigate to="/auth/signin" />;
  }
  return children;
}
