import { useEffect, useState } from "react";
import SearchResultBlock from "../../components/Block";
import Map from "../map/Map";
import BuildingData from "../address/BuildingData";
import { useAppSelector } from "../../app/hooks";
import { selectAddress } from "../address/addressSlice";
import "./Search.css";
import { ResultBlockType } from "../../types";

export default function MapSearchResultBlock({
  iconType,
  title,
  id,
}: ResultBlockType) {
  const address = useAppSelector(selectAddress);

  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.866667,
    lng: 2.333333,
  });

  useEffect(() => {
    if (
      !address ||
      !address.value ||
      !address.value.latitude ||
      !address.value.longitude
    ) {
      return;
    }
    setCenter({ lat: address.value.latitude, lng: address.value.longitude });
    setZoom(18);
  }, [address]);

  return (
    <SearchResultBlock
      style={{ display: "flex", flexDirection: "row" }}
      className="MapResultBlock"
      iconType={iconType}
      id={id}
      title={title}
    >
      <div style={{ flex: 1, paddingRight: "10px" }}>
        <Map
          mapId="SearchMap"
          center={center}
          zoom={zoom}
          style={{ height: "400px", margin: "10px 0" }}
          data4HomeInfo={
            address && address.value
              ? {
                  buildings: address.value.buildings,
                  parcels: address.value.parcels,
                  empriseBatieParis: address.value.empriseBatieParis,
                }
              : undefined
          }
        />
        <BuildingData buildingDataList="buildingData2" />
      </div>
      <BuildingData style={{ flex: 1 }} buildingDataList="buildingData1" />
    </SearchResultBlock>
  );
}
