import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserAnnotationState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

const initialState: UserAnnotationState = {
    status: 'idle',
    value: undefined,
    error: undefined
}

export const createOrUpdateAlertAction = createAction('alert/manage/create-or-update', function prepare(operation: any, alert: any) {
    return {
        payload: {
            operation,
            alert
        },
    }
}) as any
createOrUpdateAlertAction.pending = createAction('alert/manage/create-or-update/pending')
createOrUpdateAlertAction.fulfilled = createAction('alert/manage/create-or-update/fulfilled', function prepare(alert: any) {
    return {
        payload: {
            alert
        }
    }
})
createOrUpdateAlertAction.rejected = createAction('alert/manage/create-or-update/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const selectAlertEditor = (state: RootState) => state.alertEditor;

export default createReducer(initialState, (builder) => {
    builder.addCase(createOrUpdateAlertAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(createOrUpdateAlertAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.alert
    })
    builder.addCase(createOrUpdateAlertAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error
    })
})
