import {weatherRisk} from "../../constants";
import {RiskType} from "../../types";
import "./index.css";

interface PropsType {
    alert: any | null;
    isRisk: boolean;
    selectedAlertType: any;
    alertOnItemClikedIsExist: boolean;
    isDisabled: boolean;
}

const FR_LOCALE = "fr-FR";

export default function WeatherItemInfos({alert, isRisk, selectedAlertType, alertOnItemClikedIsExist, isDisabled}: PropsType): JSX.Element {
    return (
        <div className="WeatherInfosWrapper">
            <div className="InfoSectionWrapper">
                {!isDisabled ? (
                    isRisk ? (
                        alert ? (
                            <AlertInfos alert={alert} alertOnItemClikedIsExist={alertOnItemClikedIsExist} selectedAlertType={selectedAlertType} />
                        ) : (
                            <p className="TextCenter">Sélectionner un risque pour afficher les détails</p>
                        )
                    ) : (
                        <p className="TextCenter">Aucune alerte identifiée</p>
                    )
                ) : (
                    <p className="TextCenter">Renseigner une adresse pour avoir des informations sur les risques météo.</p>
                )}
            </div>
        </div>
    );
}

const AlertInfos = ({alert, selectedAlertType, alertOnItemClikedIsExist}: any): JSX.Element => {
    const getRiskLabel = (type: string): string => {
        return weatherRisk.filter((risk: RiskType) => type === risk.id)[0].label;
    };

    const getDateFormat = (date: string, locale: string): string => {
        return new Date(date).toLocaleDateString(locale, {day: "numeric", month: "long", year: "numeric"});
    };

    const renderSpecificWeatherInfos = (alert: any): JSX.Element | null => {
        const specificWeatherInfos: Record<string, JSX.Element> = {
            THUNDER: thunderInfos(alert),
            WINDS: windsInfo(alert),
            SNOW: snowInfo(alert),
            HEAT_WAVE: heatWaveInfo(alert),
            SIMPLE_HEAT_WAVE: heatWaveInfo(alert),
            COLD_WAVE: coldWaveInfo(alert),
            HEAVY_RAIN: heavyRainInfos(alert),
            POLLUTION: pollutionInfos(alert),
            SUBMERSION: submersionInfos(alert),
            ICE: iceInfos(alert),
            FLOOD: floodInfos(alert),
        };
        return specificWeatherInfos[alert.type];
    };

    if (!alertOnItemClikedIsExist) {
        return (
            <>
                {selectedAlertType && <h3>{getRiskLabel(selectedAlertType)}</h3>}
                <p className="TextCenter">Aucune alerte identifiée</p>
            </>
        );
    }
    return (
        <>
            <h3>{getRiskLabel(alert.type)}</h3>
            <div className="InfoSection">
                <ul className="GeneralInfos">
                    <li>Date de début : {getDateFormat(alert.expected_at, FR_LOCALE)}</li>
                    <li>Durée du risque : {alert.risk_duration} h</li>
                    <li>Date de fin : {getDateFormat(alert.estimated_end, FR_LOCALE)}</li>
                </ul>
                <ul className="SpecificInfos">{renderSpecificWeatherInfos(alert)}</ul>
            </div>
        </>
    );
};

const thunderInfos = (alert: any): JSX.Element => (
    <>
        {alert.hail && (
            <>
                <li>Taille maximum des grêlons : {alert.hail_size_max} cm</li>
                <li>Taille moyenne des grêlons : {alert.hail_size_avg} cm</li>
            </>
        )}
    </>
);
const windsInfo = (alert: any): JSX.Element => (
    <>
        <li>Vitesse moyenne des vents : {alert.high_wind_average} km/h</li>
        <li>Vitesse maximale des rafales : {alert.high_wind_gust} km/h</li>
    </>
);
const snowInfo = (alert: any): JSX.Element => (
    <>
        <li>Hauteur des précipitations maximal : {alert.snow_precipitation_high} mm</li>
        <li>Hauteur des précipitations minimal : {alert.snow_precipitation_low} mm</li>
    </>
);
const heatWaveInfo = (alert: any): JSX.Element => (
    <>
        <li>Température maximum : {alert.temperatures_max_heatwave} °C</li>
        <li>Température mininum : {alert.temperatures_min_heatwave} °C</li>
    </>
);
const coldWaveInfo = (alert: any): JSX.Element => (
    <>
        <li>Température maximum : {alert.temperatures_max_coldwave} °C</li>
        <li>Température mininum : {alert.temperatures_min_coldwave} °C</li>
    </>
);
const heavyRainInfos = (alert: any): JSX.Element => (
    <>
        <li>Quantité de pluie {alert.rain} mm</li>
    </>
);

const submersionInfos = (alert: any): JSX.Element => (
    <>
        <li>Hauteur des vagues {alert.wave_height} m</li>
        <li>Hauteur de la houle {alert.swell_height} m</li>
        <li>Hauteur totale {alert.total_height} m</li>
    </>
);

const iceInfos = (alert: any): JSX.Element => (
    <>
        <li>Température de gel {alert.frost_temperature} °C</li>
        <li>Point de rosée {alert.frost_temperature_dew} °C</li>
    </>
);

const floodInfos = (alert: any): JSX.Element => (
    <>
        <li>Hauteur {alert.flood_height} m</li>
    </>
);

const pollutionInfos = (alert: any): JSX.Element => (
    <li>
        <table className="PollutionTable">
            <thead>
                <tr className="PollutionInfosItem">
                    <th>type</th>
                    <th>max (ug/m3)</th>
                    <th>moy (ug/m3)</th>
                </tr>
            </thead>
            <tbody>
                {alert.polluants?.map(
                    (elt: any, id: number): JSX.Element => (
                        <tr className="PollutionInfosItem" key={`pollution-item-${id}`}>
                            <td>{elt.type}</td>
                            <td>{elt.max}</td>
                            <td>{elt.avg}</td>
                        </tr>
                    )
                )}
            </tbody>
        </table>
    </li>
);
