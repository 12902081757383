import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ArretPerilState {
  status: "idle" | "loading" | "failed";
  value?: any;
  error?: string;
}

const initialState: ArretPerilState = {
  status: "idle",
  value: undefined,
  error: undefined,
};

export const fetchPerilAction = createAction(
  "peril/fetch",
  function prepare(lat: any, lng: any) {
    return {
      payload: {
        lat,
        lng,
      },
    };
  }
) as any;
fetchPerilAction.pending = createAction("peril/fetch/pending");
fetchPerilAction.fulfilled = createAction(
  "peril/fetch/fulfilled",
  function prepare(perils: any) {
    return {
      payload: {
        perils,
      },
    };
  }
);
fetchPerilAction.rejected = createAction(
  "peril/fetch/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const createNewPerilAction = createAction(
  "peril/create",
  function prepare(newPeril: any) {
    return {
      payload: {
        newPeril,
      },
    };
  }
) as any;
createNewPerilAction.pending = createAction("peril/create/pending");
createNewPerilAction.fulfilled = createAction(
  "peril/create/fulfilled",
  function prepare({ success, newPeril }: any) {
    return {
      payload: {
        success,
        newPeril,
      },
    };
  }
);
createNewPerilAction.rejected = createAction(
  "peril/create/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const updatePerilAction = createAction(
  "peril/update",
  function prepare(id: any, newPeril: any) {
    return {
      payload: {
        id,
        newPeril,
      },
    };
  }
) as any;
updatePerilAction.pending = createAction("peril/update/pending");
updatePerilAction.fulfilled = createAction(
  "peril/update/fulfilled",
  function prepare({ success, newPeril }: any) {
    return {
      payload: {
        success,
        newPeril,
      },
    };
  }
);
updatePerilAction.rejected = createAction(
  "peril/update/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const deletePerilAction = createAction(
  "peril/delete",
  function prepare(id: any) {
    return {
      payload: {
        id,
      },
    };
  }
) as any;
deletePerilAction.pending = createAction("peril/delete/pending");
deletePerilAction.fulfilled = createAction(
  "peril/delete/fulfilled",
  function prepare({ success, message }: any) {
    return {
      payload: {
        success,
        message,
      },
    };
  }
);
deletePerilAction.rejected = createAction(
  "peril/delete/rejected",
  function prepare(error: any) {
    return {
      payload: {
        error,
      },
    };
  }
);

export const selectArretePeril = (state: RootState) => state.perils;

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchPerilAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(fetchPerilAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload.perils;
  });
  builder.addCase(fetchPerilAction.rejected, (state, action) => {
    state.status = "failed";
    state.value = undefined;
    state.error = action.payload.error;
  });

  builder.addCase(createNewPerilAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(createNewPerilAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload;
  });
  builder.addCase(createNewPerilAction.rejected, (state, action) => {
    state.status = "failed";
    state.error = action.payload.error;
  });

  builder.addCase(updatePerilAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(updatePerilAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload;
  });
  builder.addCase(updatePerilAction.rejected, (state, action) => {
    state.status = "failed";
    state.error = action.payload.error;
  });

  builder.addCase(deletePerilAction.pending, (state, action) => {
    state.status = "loading";
    state.error = undefined;
  });
  builder.addCase(deletePerilAction.fulfilled, (state, action) => {
    state.status = "idle";
    state.value = action.payload;
  });
  builder.addCase(deletePerilAction.rejected, (state, action) => {
    state.status = "failed";
    state.value = undefined;
    state.error = action.payload.error;
  });
});
