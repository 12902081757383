import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AlertMapState {
    status: 'idle' | 'loading' | 'failed',
    value?: any,
    error?: string
}

const initialState: AlertMapState = {
    status: 'idle',
    value: undefined,
    error: undefined
}

export const fetchAlertMapAction = createAction('map/alert/fetch', function prepare(isFeatureCollection : boolean) {
    return {
        payload: {
            isFeatureCollection
        },
    }
}) as any
fetchAlertMapAction.pending = createAction('map/alert/fetch/pending')
fetchAlertMapAction.fulfilled = createAction('map/alert/fetch/fulfilled', function prepare(geojson) {
    return {
        payload: {
            geojson
        }
    }
})
fetchAlertMapAction.rejected = createAction('map/alert/fetch/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const fetchAlertMapDayAction = createAction('map/alert/fetch/day', function prepare(isFeatureCollection : boolean, startDate: Date, endDate: Date) {
    return {
        payload: {
            isFeatureCollection,
            startDate,
            endDate
        },
    }
}) as any
fetchAlertMapDayAction.pending = createAction('map/alert/fetch/day/pending')
fetchAlertMapDayAction.fulfilled = createAction('map/alert/fetch/day/fulfilled', function prepare(geojson) {
    return {
        payload: {
            geojson
        }
    }
})
fetchAlertMapDayAction.rejected = createAction('map/alert/fetch/day/rejected', function prepare(error: any) {
    return {
        payload: {
            error
        }
    }
})

export const selectAlertMap = (state: RootState) => state.alertMap;

export default createReducer(initialState, (builder) => {
    builder.addCase(fetchAlertMapAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(fetchAlertMapAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.geojson
    })
    builder.addCase(fetchAlertMapAction.rejected, (state, action) => {
        state.status = 'failed';
        state.value = undefined
        state.error = action.payload.error
    })

    builder.addCase(fetchAlertMapDayAction.pending, (state, action) => {
        state.status = 'loading'
        state.error = undefined
    })
    builder.addCase(fetchAlertMapDayAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.geojson
    })
    builder.addCase(fetchAlertMapDayAction.rejected, (state, action) => {
        state.status = 'failed';
        state.value = undefined
        state.error = action.payload.error
    })
})
