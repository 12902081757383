import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_FR } from "./locales/fr";
import { TRANSLATIONS_EN } from "./locales/en";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  compatibilityJSON: 'v3',
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     fr: {
       translation: TRANSLATIONS_FR
    },
   }
 });
 
i18n.changeLanguage("fr");

export default i18n;
