import {useState, useEffect} from "react";
import "./index.css";
import Icon from "../Icon";

export default function Carousel(props: any) {
    const {children} = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(children.length);

    useEffect(() => {
        setLength(children.length);
    }, [children]);

    const next = () => {
        if (currentIndex < length - 1) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {currentIndex > 0 && (
                    <button onClick={prev} className="left-arrow">
                        <Icon type="leftArrow" />
                    </button>
                )}
                <div className="carousel-content-wrapper">
                    <div className="carousel-content" style={{transform: `translateX(-${currentIndex * 100}%)`}}>
                        {children}
                    </div>
                </div>
                <div className="page-number-wrapper">
                    {children.map((element: any, index: number) => (
                        <div key={`page-${element.key}`} className={`pagination-item ${currentIndex === index ? "active" : ""}`}></div>
                    ))}
                </div>
                {currentIndex < length - 1 && (
                    <button onClick={next} className="right-arrow">
                        <Icon type="rightArrow" />
                    </button>
                )}
            </div>
        </div>
    );
}
