import { Link } from "react-router-dom";
import "./Auth.css";

export default function Copyright() {
  return (
    <p className="AuthTextCopyRight">
      {"Copyright © "}
      <Link
        color="inherit"
        to="https://www.data4risk.com/"
        target="_blank"
        className="AuthTextCopyRight"
      >
        Data4Risk
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </p>
  );
}
