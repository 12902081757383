import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "../../components/Icon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: 4,
    border: "1px solid #CCCCCC",
    width: "100%",
    height: "38px",
  },
  input: {
    border: "none",
    textAlign: "center",
    minWidth: 0,
    maxWidth: 100,
  },
});

interface CustomInputDateProps {
  value?: string;
  onClick?: () => void;
}

const CustomInputDate = React.forwardRef<
  HTMLInputElement,
  CustomInputDateProps
>((props, ref) => {
  const { value, onClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        style={{
          backgroundColor: "white",
          width: "100%",
        }}
        type="button"
        value={value}
        onClick={onClick}
        ref={ref}
      />
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Icon type="calendarDateTime" />
      </div>
    </div>
  );
});

export default CustomInputDate;
