// for link the catnat to icon
export const modelIconCatnat : {[key:string]:string} =
{
    landslide: "Glissements de terrain",
    submersion: "Submersion",
    drought: "Sécheresse",
    flood: "Inondation - Fortes pluies et Tempêtes",
    avalanche: "Avalanche",
    earthquake: "Seisme",
    storm: "Tempête",
    coastalErosion: "Érosion côtière",
}